import * as T from '@aily/graphql-sdk/schema';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface GroupedFiltersContextState {
  updateGroupValue: (value: number, filterSyncGroup?: string) => void;
  syncFilterComponentsWithGroups: (filterComponents: T.FilterComponent[]) => T.FilterComponent[];
}

const GroupedFiltersContext = createContext<GroupedFiltersContextState | undefined>(undefined);

interface GroupedFiltersProviderProps extends PropsWithChildren {
  groups?: Record<string, number>;
  onUpdateGroupValue?: (filterSyncGroup: string, value: number) => void;
}

export const GroupedFiltersProvider: React.FC<GroupedFiltersProviderProps> = ({
  groups: defaultGroups,
  onUpdateGroupValue,
  children,
}) => {
  const [groups, setGroups] = useState<Record<string, number>>(defaultGroups ?? {});

  const syncFilterComponentsWithGroups = useCallback(
    (filterComponents: T.FilterComponent[]) =>
      filterComponents.reduce((acc: T.FilterComponent[], filterComponent: T.FilterComponent) => {
        if (filterComponent.filterSynchronizationGroup) {
          const groupValue = groups[filterComponent.filterSynchronizationGroup];

          if (groupValue) {
            acc.push({ ...filterComponent, defaultValue: groupValue });
            return acc;
          }
        }

        return acc;
      }, []),
    [groups],
  );

  const updateGroupValue = useCallback(
    (value: number, filterSyncGroup?: string) => {
      if (filterSyncGroup) {
        setGroups((prevGroups) => ({ ...prevGroups, [filterSyncGroup]: value }));
        onUpdateGroupValue?.(filterSyncGroup, value);
      }
    },
    [onUpdateGroupValue],
  );

  const value = useMemo(() => {
    return {
      updateGroupValue,
      syncFilterComponentsWithGroups,
    };
  }, [updateGroupValue, syncFilterComponentsWithGroups]);

  return <GroupedFiltersContext.Provider value={value}>{children}</GroupedFiltersContext.Provider>;
};

export function useGroupedFilters(): GroupedFiltersContextState | undefined {
  return useContext(GroupedFiltersContext);
}
