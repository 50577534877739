import { alpha, styled, Typography, TypographyProps, useTheme, useThemeProps } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';

import { useResponsiveValue, useThemeColor } from '../../hooks';
import { AnimatedCircle, LoadingCircle } from '../AnimatedCircle';
import { CircularProgressProps } from '../CircularProgress';
import { FitText } from '../FitText';
import { GaugeContent } from '../GaugeContent';

export interface RadialGaugeProps {
  size?: number;
  color?: 'error' | 'success' | 'warning' | 'info' | string;
  varianceColor?: 'error' | 'success' | string;
  lineWidth?: number;
  progress?: number;
  progressText?: string;
  target?: number;
  label?: string;
  LabelTypographyProps?: TypographyProps;
  actualText?: string;
  varianceText?: string;
  varianceAIText?: string;
  varianceTextColor?: string;
  varianceAITextColor?: string;
  loading?: boolean;
  targetText?: string;
  isRedirectDial?: boolean;
  onToggle?: (toggled: boolean) => void;
}

export const GaugeContentComponent = ({
  children,
  size,
}: {
  children: React.ReactNode;
  size?: number;
}) => (
  <GaugeContent
    sx={{
      width: '100%',
      height: size ?? '100%',
      p: 3,
      pb: 4,
      '& .MuiTypography-root, & .MuiTypography-root > *': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        userSelect: 'none',
      },
    }}
  >
    {children}
  </GaugeContent>
);

const RadialGaugeRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ size }) => ({
  position: 'relative',
  width: size,
  height: size,
  '& > svg': {
    width: '100%',
  },
  '& .smallVariance': {
    fontSize: '1em',
  },
}));

const RadialGaugeGlow = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ size: number; color: string }>(({ size, color }) => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  width: '100%',
  paddingBottom: '100%',
  transform: 'translateY(-50%)',
  borderRadius: '50%',
  boxShadow: `0 0 ${size / 3}px 0px ${alpha(color, 0.2)}`,
  pointerEvents: 'none',
}));

const RadialGauge: React.FC<RadialGaugeProps> = ({
  size: sizeProp,
  color,
  varianceColor,
  lineWidth,
  progress = 0,
  progressText,
  target,
  label = '',
  LabelTypographyProps,
  actualText,
  varianceText,
  varianceAIText,
  varianceTextColor,
  varianceAITextColor,
  targetText,
  loading,
  isRedirectDial = false,
  onToggle,
}) => {
  const theme = useTheme();
  const themeProps = useThemeProps({
    props: { size: sizeProp, thickness: lineWidth } as CircularProgressProps,
    name: 'CircularProgress',
  });

  const size = useResponsiveValue(themeProps.size ?? 154, 154);
  const thickness = useResponsiveValue(themeProps.thickness ?? 14, 14);
  const trackColor = themeProps.trackColor ?? theme.palette.background.paper;
  const lineColor =
    useThemeColor(`${varianceColor ?? color}.main`) ?? color ?? theme.palette.info.main;
  const varianceTextC =
    useThemeColor(`${varianceTextColor}.main`) ?? varianceTextColor ?? theme.palette.info.main;
  const varianceAIC =
    useThemeColor(`${varianceAITextColor}.main`) ?? varianceAITextColor ?? theme.palette.info.main;
  const gradientColors = useThemeColor<[string, string]>(`${color}.gradient`);

  const [toggled, setToggled] = useState(false);

  const handleToggle = useCallback(
    (toggled: boolean) => {
      setToggled(toggled);
      onToggle?.(toggled);
    },
    [onToggle],
  );

  const defaultContent = useMemo(
    () => (
      <GaugeContentComponent key="default">
        {!!progressText && (
          <Typography
            variant="h4"
            data-testid="Actual"
            sx={{
              cursor: actualText || isRedirectDial ? 'pointer' : 'default',
              mb: -0.5,
              lineHeight: '44px',
            }}
            onClick={actualText ? () => handleToggle(true) : undefined}
          >
            <FitText>{progressText}</FitText>
          </Typography>
        )}
        <Typography variant="bodyTight" {...LabelTypographyProps} data-testid="Label">
          <FitText>{label}</FitText>
        </Typography>
      </GaugeContentComponent>
    ),
    [progressText, actualText, isRedirectDial, LabelTypographyProps, label, handleToggle],
  );

  const renderVarianceText = () => {
    if (varianceAIText && varianceText) {
      return (
        <div>
          <Typography
            variant="h8"
            sx={{ color: varianceTextC }}
            className={varianceText.length >= 6 ? 'smallVariance' : ''}
            data-testid="Variance"
          >
            {varianceText}
          </Typography>
          /
          <Typography
            variant="h8"
            sx={{ color: varianceAIC }}
            className={varianceText.length >= 6 ? 'smallVariance' : ''}
            data-testid="Variance"
          >
            {varianceAIText}
          </Typography>
        </div>
      );
    } else if (varianceText) {
      return (
        <Typography variant="h8" sx={{ color: lineColor }} data-testid="Variance">
          <FitText minFontSize={80}>{varianceText}</FitText>
        </Typography>
      );
    }
  };

  const toggledContent = useMemo(
    () =>
      targetText ? (
        <GaugeContentComponent key="toggled">
          {!!actualText && (
            <Typography
              variant="h6"
              data-testid="Actual"
              sx={{ cursor: 'pointer', mb: -0.5, lineHeight: '44px' }}
              onClick={() => handleToggle(false)}
            >
              <FitText minFontSize={60}>{actualText}</FitText>
            </Typography>
          )}
          <Typography variant="bodyTight" {...LabelTypographyProps} data-testid="Label">
            <FitText minFontSize={80}>{label}</FitText>
          </Typography>
          <Typography variant="body" color="text.secondary">
            {targetText}
          </Typography>
        </GaugeContentComponent>
      ) : (
        <GaugeContentComponent key="toggledAlt">
          {!!actualText && (
            <Typography
              variant="h4"
              data-testid="Actual"
              sx={{ cursor: 'pointer', mb: -0.5, lineHeight: '44px' }}
              onClick={() => handleToggle(false)}
            >
              <FitText minFontSize={60}>{actualText}</FitText>
            </Typography>
          )}
          {renderVarianceText()}
          <Typography variant="bodyTight" {...LabelTypographyProps} data-testid="Label">
            <FitText minFontSize={80}>{label}</FitText>
          </Typography>
        </GaugeContentComponent>
      ),
    [
      actualText,
      varianceText,
      targetText,
      lineColor,
      label,
      LabelTypographyProps,
      handleToggle,
      renderVarianceText,
    ],
  );

  const memoizedLineColor = useMemo(() => {
    let gradientDirection = 'to bottom'; // Default direction

    // Reverse the gradient direction for this particular case
    if (color === 'success') {
      gradientDirection = 'to top';
    }

    if (themeProps.fillType === 'gradient' && Array.isArray(gradientColors)) {
      return `linear-gradient(${gradientDirection}, ${gradientColors.join(',')})`;
    }

    return lineColor;
  }, [themeProps, gradientColors, color, lineColor]);

  return loading ? (
    <LoadingCircle size={size} lineWidth={thickness} data-testid="RadialGaugeSkeleton" />
  ) : (
    <RadialGaugeRoot size={size}>
      {themeProps.glow && (
        <RadialGaugeGlow
          size={size}
          color={lineColor}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
        />
      )}
      <AnimatedCircle
        size={size}
        lineWidth={thickness}
        lineColor={memoizedLineColor}
        trackColor={trackColor}
        progress={progress}
        data-testid="RadialGauge"
      >
        {typeof target === 'number' && (
          <g style={{ transform: 'rotate(-90deg)', transformOrigin: 'center' }}>
            <circle
              r={thickness / 2 - 2}
              cx={size - thickness / 2}
              cy="50%"
              fill="#fff"
              stroke="none"
              style={{ transform: `rotate(${target * 360}deg)`, transformOrigin: 'center' }}
            />
          </g>
        )}
        <foreignObject x="0" y="0" width="100%" height="100%">
          {toggled ? toggledContent : defaultContent}
        </foreignObject>
      </AnimatedCircle>
    </RadialGaugeRoot>
  );
};

export default RadialGauge;
