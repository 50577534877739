// chart due to its complexity for now remains untyped. Hopefully its type will change to one that is being used everywhere else outside of agent
import * as T from '@aily/graphql-sdk/schema';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useMemo, useState } from 'react';

import { ChartDataViewAbstractType } from '../../../../components/ChartDataView/types';
import config from '../../../../config/highchartsConfig';
import { colors } from '../../../../theme/default/colors';
import { ChartType } from '../../types/agentJsonData';
import { getChartConfig, getChartSeries, getYaxisMax } from './config';

const monthAbbreviations = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

type PopupChartProps = {
  data: ChartDataViewAbstractType & {
    chart_type: ChartType;
    tick_amount: number;
    x_ticks: number[];
    y_ticks: number[];
    y_label: string;
  };
  height?: number;
  width?: number;
};

export const PopupChart: React.FC<PopupChartProps> = ({ data, height = 280, width }) => {
  const [series, setSeries] = useState([]);
  const [currentChart, setCurrentChart] = useState<any>([]);
  const [category, setCategory] = useState<T.Maybe<string[]> | undefined>([]);

  useEffect(() => {
    const currentChart = getChartConfig(height);
    // @ts-expect-error due to time constraints, this remains untyped, read comment on top
    const seriesData = getChartSeries(data, data.chart_type, currentChart);
    const category = [
      ...new Set(
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        data.series.reduce(
          (carry: string[], { data }) => [
            ...carry,
            // @ts-expect-error due to time constraints, this remains untyped, read comment on top
            ...data.map(
              ({ x }) =>
                // @ts-expect-error due to time constraints, this remains untyped, read comment on top
                `${monthAbbreviations?.[Number(x?.split('-')?.[1]) - 1]} ${x?.split('-')?.[0].slice(-2)}`,
            ),
          ],
          [],
        ),
      ),
    ] as string[];

    setCurrentChart(currentChart);
    // @ts-expect-error due to time constraints, this remains untyped, read comment on top
    setSeries(seriesData);
    setCategory(category);
  }, [data, height]);

  const chartOptions = useMemo(
    () => ({
      ...currentChart,
      xAxis: {
        ...currentChart?.xAxis,
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        categories: category || data?.series.map((series) => series?.name),
        startOnTick: false,
        endOnTick: false,
        minPadding: 0,
        maxPadding: 0,
        type: 'datetime',
        labels: {
          format: '{value:%b %y}',
          style: {
            fontWeight: 700,
            fontSize: 12,
            color: colors.neutrals.grey4,
          },
        },
        title: {
          text: '',
          align: 'high',
          style: {
            fontWeight: 800,
            fontSize: 12,
            color: colors.neutrals.grey4,
          },
        },
      },
      yAxis: {
        ...currentChart?.yAxis,
        ...(data?.x_ticks && { tickAmount: data.x_ticks }),
        ...(data?.y_ticks && { tickPositions: data.y_ticks }),
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        max: getYaxisMax(data?.series, currentChart?.chart?.type),
        labels: {
          formatter: ({ value }: { value: number }) => {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(1) + 'B';
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + 'k';
            }
            return value;
          },
          style: {
            fontWeight: 800,
            fontSize: 12,
            color: colors.neutrals.grey4,
            border: '1px solid red',
          },
        },
        title: {
          text: '',
          border: '1px solid red',
          style: {
            fontWeight: 800,
            fontSize: 12,
            color: colors.neutrals.grey4,
          },
        },
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        backgroundColor: 'transparent',
        itemStyle: {
          color: 'rgba(255, 255, 255)',
          fontSize: '14px',
          fontWeight: 'bold',
        },
        itemDistance: 10,
        margin: 0,
        padding: 5,
        itemHoverStyle: {
          color: '#FFD700',
        },
        symbolWidth: 0,
        useHTML: true,
        labelFormatter: function (): string {
          // @ts-expect-error due to time constraints, this remains untyped, read comment on top
          return `<span style="display: inline-block; width: 12px; height: 12px; background-color: ${this.color}; border-radius: 50%;"></span> ${this.name}`;
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
      },
      series,
    }),
    [currentChart, category, data?.series, series, data.tick_amount, data?.x_ticks, data?.y_ticks],
  );

  return (
    <HighchartsReact
      highcharts={config}
      options={chartOptions}
      containerProps={{ style: { height, width } }}
    />
  );
};
