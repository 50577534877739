import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

import { useSlideshow } from './SlideshowContext';
import { SlideshowSlideGroupProps } from './SlideshowSlideGroup';

export interface SlideshowScreenProps extends BoxProps {
  index: number;
  children:
    | React.ReactElement<SlideshowSlideGroupProps>
    | React.ReactElement<SlideshowSlideGroupProps>[]
    | React.ReactNode;
  header?: React.ReactNode;
  ScreenHeaderProps?: BoxProps;
  ScreenContentProps?: BoxProps;
}

const ScreenContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: '100%',
  height: '100%',
});

const ScreenHeader = styled(Box)({});

const ScreenContent = styled(Box)({
  position: 'relative',
  flex: 1,
});

export const SlideshowScreen: React.FC<SlideshowScreenProps> = ({
  index,
  children,
  header,
  ScreenHeaderProps,
  ScreenContentProps,
  ...rest
}) => {
  const { state } = useSlideshow();
  const isActive = index === state.currentScreen;

  return (
    isActive && (
      <ScreenContainer data-testid="slideshow__screen" {...rest}>
        {!!header && (
          <ScreenHeader data-testid="slideshow__screen-header" {...ScreenHeaderProps}>
            {header}
          </ScreenHeader>
        )}
        <ScreenContent data-testid="slideshow__screen-content" {...ScreenContentProps}>
          {children}
        </ScreenContent>
      </ScreenContainer>
    )
  );
};
