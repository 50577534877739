import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

import { useSlideshow } from './SlideshowContext';
import { SlideshowSlideProps } from './SlideshowSlide';

export interface SlideshowSlideGroupProps extends BoxProps {
  index: number;
  children: React.ReactElement<SlideshowSlideProps> | React.ReactElement<SlideshowSlideProps>[];
}

const SlideGroupContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

export const SlideshowSlideGroup: React.FC<SlideshowSlideGroupProps> = ({
  index,
  children,
  ...rest
}) => {
  const { state } = useSlideshow();
  const isActive = index === state.currentGroup;

  return (
    isActive && (
      <SlideGroupContainer data-testid="slideshow__slide-group" {...rest}>
        {children}
      </SlideGroupContainer>
    )
  );
};
