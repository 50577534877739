import { AilyLogo, config, HStack, Text } from '@aily-labs/ui';

export const AilyRecommends = () => (
  <HStack height={28} alignItems="center" justifyContent="center">
    <AilyLogo height={14} width={22} />
    <Text
      marginLeft={config.tokens.space.spacingXXSmall}
      type="bold"
      fontSize="$fontSize12"
      color="$white"
    >
      recommends this scenario
    </Text>
  </HStack>
);
