import { useAuth } from '@aily/auth-service';
import { useCallback, useEffect, useRef } from 'react';

import { useAnalytics } from '../providers';
import { TrackEventFunction } from '../types';
import { useAnalyticsCommonEventProps } from '.';

export function useAnalyticsTrackEvent(): TrackEventFunction {
  const { track, useCurrentModule } = useAnalytics();
  const { isAuthenticated } = useAuth();
  const currentModule = useCurrentModule();
  const commonEventProps = useAnalyticsCommonEventProps();
  const valuesRef = useRef({ currentModule, ...commonEventProps });

  useEffect(() => {
    valuesRef.current = { currentModule, ...commonEventProps };
  }, [currentModule, commonEventProps]);

  // Define the function using the refs
  return useCallback<TrackEventFunction>(
    (eventName, eventProps) => {
      if (!isAuthenticated) return;
      const { currentModule, ...commonEventProps } = valuesRef.current;
      if (currentModule) {
        track(eventName, {
          ...commonEventProps,
          ...eventProps,
          module: currentModule.name,
        });
      }
    },
    [track],
  );
}
