import { getCurrentUser, useAuth } from '@aily/auth-service';
import {
  ProcessInterceptMutationVariables,
  useInterceptSubscription,
  useProcessInterceptMutation,
} from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { useDeepCompareMemoize, useHandleLink } from '@aily/saas-core';
import { Portal } from '@mui/material';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import InterceptComponent from '@/components/Intercept/InterceptComponent';
import { useCurrentModule } from '@/hooks';

interface InterceptionsContextState {
  interceptionsQueue: T.InterceptResult[];
  enqueueInterception: (intercept: T.InterceptResult) => void;
  dequeueInterception: () => void;
  clearInterceptions: () => void;
  wasSubscriptionCalled: boolean;
}

const InterceptionsContext = createContext<InterceptionsContextState | undefined>(undefined);

export const InterceptionsProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const currentModule = useCurrentModule();
  const [wasSubscriptionCalled, setWasSubscriptionCalled] = useState(false);
  const [interceptionsQueue, setInterceptionsQueue] = useState<T.InterceptResult[]>([]);

  const input = useDeepCompareMemoize({
    userId: getCurrentUser()?.profile.sub ?? '',
    originName: 'web',
  });

  const { data, loading } = useInterceptSubscription({
    skip: !isAuthenticated,
    variables: { input },
  });

  useEffect(() => {
    if (loading) {
      setWasSubscriptionCalled(true);
    }
  }, [loading]);

  const [processIntercept] = useProcessInterceptMutation();
  const handleLink = useHandleLink();

  const enqueueInterception = useCallback((intercept: T.InterceptResult) => {
    setInterceptionsQueue((prevQueue) => [...prevQueue, intercept]);
  }, []);

  const dequeueInterception = useCallback(() => {
    setInterceptionsQueue((prevQueue) => prevQueue.slice(1));
  }, []);

  const clearInterceptions = useCallback(() => {
    setInterceptionsQueue([]);
  }, []);

  const handleOk = useCallback(
    (intercept: T.InterceptResult) => () => {
      processIntercept({
        variables: {
          input: {
            moduleCode: (currentModule?.moduleCode ?? '') as T.ModuleCode,
            interceptId: intercept.id,
            action: T.InterceptActionType.Followed,
          },
        } as ProcessInterceptMutationVariables,
      });

      const { link } = intercept;
      if (link) {
        handleLink(link);
        clearInterceptions();
        return;
      }

      dequeueInterception();
    },
    [currentModule, handleLink, clearInterceptions, dequeueInterception, processIntercept],
  );

  const handleCancel = useCallback(
    (intercept: T.InterceptResult) => () => {
      processIntercept({
        variables: {
          input: {
            moduleCode: (currentModule?.moduleCode ?? '') as T.ModuleCode,
            interceptId: intercept.id,
            action: T.InterceptActionType.Dismissed,
          },
        } as ProcessInterceptMutationVariables,
      });

      dequeueInterception();
    },
    [currentModule, dequeueInterception, processIntercept],
  );

  const currentIntercept = interceptionsQueue[0];

  const contextValue = useMemo(
    () => ({
      interceptionsQueue,
      enqueueInterception,
      dequeueInterception,
      clearInterceptions,
      wasSubscriptionCalled,
    }),
    [
      interceptionsQueue,
      enqueueInterception,
      dequeueInterception,
      clearInterceptions,
      wasSubscriptionCalled,
    ],
  );

  useEffect(() => {
    if (data?.intercept) {
      enqueueInterception(data.intercept);
    }
  }, [data, enqueueInterception]);

  return (
    <InterceptionsContext.Provider value={contextValue}>
      {children}
      <Portal>
        {currentIntercept && (
          <InterceptComponent
            key={currentIntercept.id}
            intercept={currentIntercept}
            onOk={handleOk(currentIntercept)}
            onCancel={handleCancel(currentIntercept)}
          />
        )}
      </Portal>
    </InterceptionsContext.Provider>
  );
};

export const useInterceptions = () => {
  const context = useContext(InterceptionsContext);

  if (!context) {
    throw new Error('useInterceptions must be used within a InterceptionsProvider');
  }

  return context;
};
