import * as T from '@aily/graphql-sdk/schema';
import { concat, groupBy, isEmpty, isEqual, isNil, map, sortBy } from 'lodash-es';

import { findDeep } from './traversal';

/**
 * Create a new filter code value.
 * @param {string} filterCode - The filter code.
 * @param {number} value - The selected filter value.
 * @returns {T.FilterCodeValue} - A filter code value.
 */
export function createFilterCodeValue(filterCode: string, value: number): T.FilterCodeValue {
  return { filterCode, value, __typename: 'FilterCodeValue' };
}

/**
 * Create a new select filter value.
 * @param {string} id - The ID of the filter.
 * @param {number} value - The selected filter value.
 * @returns {T.SelectFilterValue} - A select filter value.
 */
export function createSelectFilterValue(id: string, value: number): T.SelectFilterValue {
  return { id, value, __typename: 'SelectFilterValue' };
}

/**
 * Create a new multi select filter value.
 * @param {string} id - The ID of the filter.
 * @param {number} selectedValues - The selected filter value.
 * @returns {T.SelectFilterValue} - A select filter value.
 */
export function createMultiSelectFilterValue(
  id: string,
  selectedValues?: number[],
): T.MultiSelectFilterValue {
  return { id, selectedValues: selectedValues ?? [], __typename: 'MultiSelectFilterValue' };
}

/**
 * Create a new select group filter value.
 * @param {string} id - The ID of the filter.
 * @param {T.FilterCodeValue[]} values - The filter code values.
 * @returns {T.SelectGroupFilterValue} - A select group filter value.
 */
export function createSelectGroupFilterValue(
  id: string,
  values: T.FilterCodeValue[],
): T.SelectGroupFilterValue {
  return { id, values, __typename: 'SelectGroupFilterValue' };
}

/**
 * Validates a filter value based on its type.
 *
 * @param {T.FilterValue} filterValue - The filter value to validate.
 * @returns {boolean} - `true` if the filter value is valid, otherwise `false`.
 */
export function isValidFilterValue(filterValue: T.FilterValue): boolean {
  return (
    (T.isSelectFilterValue(filterValue) && filterValue.value && filterValue.value !== -1) ||
    (T.isMultiSelectFilterValue(filterValue) && !isEmpty(filterValue.selectedValues)) ||
    (T.isSelectGroupFilterValue(filterValue) && !isEmpty(filterValue.values))
  );
}

/**
 * Finds a filter option that satisfies a given predicate.
 *
 * @param filterOptions - An array or tree of filter options to search through.
 * @param predicate - A function to test each element for a condition.
 * @return The value of the first filter option meeting the condition, or undefined.
 */
export function findFilterOption(
  filterOptions: readonly T.FilterOptionResult[] | T.TreeFilterOptionResult[],
  predicate: (filterOption: T.FilterOptionResult | T.TreeFilterOptionResult) => boolean,
) {
  return findDeep<T.FilterOptionResult | T.TreeFilterOptionResult>(filterOptions, predicate)?.value;
}

/**
 * Gets the ID of the filter from a filter input.
 * @param {T.FilterInput} filterInput - The filter input.
 * @returns {string | undefined} - The ID of the filter, otherwise undefined.
 */
export function getFilterIdFromFilterInput(filterInput: T.FilterInput): string | undefined {
  if ('selectFilter' in filterInput && filterInput.selectFilter) {
    return filterInput.selectFilter.id;
  }

  if ('selectGroupFilter' in filterInput && filterInput.selectGroupFilter) {
    return filterInput.selectGroupFilter.id;
  }

  return undefined;
}

/**
 * Checks whether a filter value has changed compared to a previous value.
 * @param oldValue - The old filter value.
 * @param newValue - The new filter value.
 * @returns true if the filter values are considered changed; otherwise, false.
 */
export function hasFilterValueChanged(oldValue?: T.FilterValue, newValue?: T.FilterValue): boolean {
  // One value is null, but the other isn't
  if ((!oldValue && newValue) || (oldValue && !newValue)) {
    return true;
  }

  // The filter IDs don't match
  if (oldValue?.id !== newValue?.id) {
    return true;
  }

  // Both values are of `SelectFilterValue` type, compare their values directly
  if (T.isSelectFilterValue(oldValue) && T.isSelectFilterValue(newValue)) {
    return oldValue.value !== newValue.value;
  }

  // Both values are of `SelectGroupFilterValue` type, compare their values after sorting by `filterCode`
  if (T.isSelectGroupFilterValue(oldValue) && T.isSelectGroupFilterValue(newValue)) {
    return !isEqual(sortBy(oldValue.values, 'filterCode'), sortBy(newValue.values, 'filterCode'));
  }

  // If filter value types don't match, consider it as a change
  return true;
}

/**
 * Map filter code values to filter code inputs.
 * @param {T.FilterCodeValue[]} values - The filter code values.
 * @returns {T.SelectFilterCodeInput[]} - An array of filter code inputs.
 */
export function mapFilterCodeValuesToFilterCodeInputs(
  values: T.FilterCodeValue[],
): T.SelectFilterCodeInput[] {
  return values.map(({ filterCode, value }) => ({ filterCode, value }));
}

/**
 * Map a filter component to a filter value.
 * @param {T.FilterComponent} filterComponent - The filter component.
 * @returns {T.FilterValue} - A filter value.
 */
export function mapFilterComponentToFilterValue(filterComponent: T.FilterComponent): T.FilterValue {
  const { id, defaultValue } = filterComponent;
  return createSelectFilterValue(id, defaultValue ?? -1);
}

/**
 * Map a filter input to a filter value.
 * @param {T.FilterInput} filterInput - The filter input.
 * @returns {T.FilterValue | undefined} - A filter value or undefined if mapping is not possible.
 */
export function mapFilterInputToFilterValue(filterInput: T.FilterInput): T.FilterValue | undefined {
  if ('selectFilter' in filterInput && filterInput.selectFilter) {
    const selectFilterInput = filterInput.selectFilter;
    return createSelectFilterValue(selectFilterInput.id, selectFilterInput.value);
  }

  if ('selectGroupFilter' in filterInput && filterInput.selectGroupFilter) {
    const selectGroupFilterInput = filterInput.selectGroupFilter;
    return createSelectGroupFilterValue(selectGroupFilterInput.id, selectGroupFilterInput.values);
  }

  if ('multiSelectFilter' in filterInput && filterInput.multiSelectFilter) {
    const multiSelectFilterInput = filterInput.multiSelectFilter;
    return createMultiSelectFilterValue(
      multiSelectFilterInput.id,
      multiSelectFilterInput.selectedValues as number[],
    );
  }

  return undefined;
}

/**
 * Map a filter option to a filter value.
 * @param {T.Filter['id']} filterId - The filter ID.
 * @param {T.FilterOptionResult | T.TreeFilterOptionResult} filterOption - The filter option.
 * @returns {T.SelectFilterValue} - A select filter value.
 */
export function mapFilterOptionToFilterValue(
  filterId: T.Filter['id'],
  filterOption: T.FilterOptionResult | T.TreeFilterOptionResult,
): T.SelectFilterValue {
  return createSelectFilterValue(filterId, filterOption.value ?? -1);
}

/**
 * Map a filter value to a filter input.
 * @param {T.FilterValue} filterValue - The filter value.
 * @returns {T.FilterInput | undefined} - A filter input or undefined if mapping is not possible.
 */
export function mapFilterValueToFilterInput(filterValue: T.FilterValue): T.FilterInput | undefined {
  if (T.isSelectFilterValue(filterValue)) {
    return { selectFilter: { id: filterValue.id, value: filterValue.value } };
  }

  if (T.isMultiSelectFilterValue(filterValue)) {
    return {
      multiSelectFilter: {
        id: filterValue.id,
        selectedValues: filterValue?.selectedValues ?? [],
      },
    };
  }

  if (T.isSelectGroupFilterValue(filterValue)) {
    return {
      selectGroupFilter: {
        id: filterValue.id,
        values: mapFilterCodeValuesToFilterCodeInputs(filterValue.values as T.FilterCodeValue[]),
      },
    };
  }

  return undefined;
}

/**
 * Reduce a filter input to a filter value.
 * @param {T.FilterValue[]} acc - The accumulator for filter values.
 * @param {T.FilterInput} filterInput - The filter input.
 * @returns {T.FilterValue[]} - An array of filter values.
 */
export function reduceFilterInputToFilterValue(
  acc: T.FilterValue[],
  filterInput: T.FilterInput,
): T.FilterValue[] {
  const filterValue = mapFilterInputToFilterValue(filterInput);
  if (filterValue) {
    acc.push(filterValue);
  }

  return acc;
}

/**
 * Reduce a filter input to select filter input.
 * @param {T.SelectFilterInput[]} acc - The accumulator for select filter inputs.
 * @param {T.FilterInput} filterInput - The filter input.
 * @returns {T.SelectFilterInput[]} - An array of select filter inputs.
 */
export function reduceFilterInputToSelectFilterInput(
  acc: T.SelectFilterInput[],
  filterInput: T.FilterInput,
): T.SelectFilterInput[] {
  if ('selectFilter' in filterInput && filterInput.selectFilter) {
    acc.push(filterInput.selectFilter);
  }

  return acc;
}

/**
 * Reduce an array of filter values to dependent filter values.
 * @param {T.FilterValue[]} filterValues - The array of filter values.
 * @param {T.FilterComponent[]} filterComponents - The array of dependent filter components.
 * @returns {T.FilterValue[]} - An array of dependent filter values.
 */
export function reduceFilterValueToDependentFilterValue(
  filterValues: readonly T.FilterValue[],
  filterComponents: readonly T.FilterComponent[],
): T.FilterValue[] {
  return filterValues.reduce((acc: T.FilterValue[], filterValue) => {
    const filterComponent = filterComponents?.find(
      (filterComponent) => filterComponent.id === filterValue.id,
    );

    if (filterComponent) {
      acc.push(filterValue);
    }

    return acc;
  }, []);
}

/**
 * Reduce a filter value to a filter input.
 * @param {T.FilterInput[]} acc - The accumulator for filter inputs.
 * @param {T.FilterValue} filterValue - The filter value.
 * @returns {T.FilterInput[]} - An array of filter inputs.
 */
export function reduceFilterValueToFilterInput(
  acc: T.FilterInput[],
  filterValue: T.FilterValue,
): T.FilterInput[] {
  const filterInput = mapFilterValueToFilterInput(filterValue);
  if (filterInput) {
    acc.push(filterInput);
  }

  return acc;
}

/**
 * Resolves the filter option based on the selected option value or defaults.
 * @param filterOptions - The array of filter options to search in.
 * @param selectedOptionValue - The value of the selected option.
 * @returns The resolved filter option based on selection, defaults, or the first available option.
 */
export function resolveFilterOption(
  filterOptions: T.FilterOptionResult[] | T.TreeFilterOptionResult[],
  selectedOptionValue?: number,
): T.FilterOptionResult | T.TreeFilterOptionResult | undefined {
  // Try to find the selected option based on the given value
  const selectedOption = !isNil(selectedOptionValue)
    ? findFilterOption(filterOptions, ({ value }) => value === selectedOptionValue)
    : undefined;

  // If no selected option is found, try to find the default option
  const defaultOption = !selectedOption
    ? findFilterOption(filterOptions, ({ isDefault, value }) => !!isDefault && !isNil(value))
    : undefined;

  // If no default option is found, try to find the first available option
  const firstAvailableOption =
    !selectedOption && !defaultOption
      ? findFilterOption(filterOptions, ({ value }) => !isNil(value))
      : undefined;

  // Return the resolved option: selected, default, or first available.
  return selectedOption ?? defaultOption ?? firstAvailableOption;
}

/**
 * Resolves filter values based on the input filters and existing filter values.
 * Determines whether new filter values should be updated or existing values should be retained.
 * @param filters - Array of filters to resolve values for.
 * @param filterValues - Existing filter values to compare and update.
 * @returns Updated array of filter values if changes are detected; otherwise, the original filterValues.
 */
export function resolveFilterValues(
  filters: T.Filter[],
  filterValues: T.FilterValue[],
): T.FilterValue[] {
  // Flag to determine if any filter value has been updated
  let isUpdated = false;

  const newFilterValues = filters.reduce((acc: T.FilterValue[], filter) => {
    let filterValue: T.FilterValue | undefined = undefined;
    let newFilterValue: T.FilterValue | undefined = undefined;

    if (T.isSelectFilter(filter) || T.isTreeSelectFilter(filter)) {
      filterValue = filterValues.find(
        (filterValue) => T.isSelectFilterValue(filterValue) && filterValue.id === filter.id,
      ) as T.SelectFilterValue | undefined;

      if (
        filter.filterType === T.FilterType.Multiselect ||
        filter.filterType === T.FilterType.TreeMultiSelect
      ) {
        // This function doesn't support resolution of multi selection filter values, so the value is kept as is
        newFilterValue = filterValue;
      } else {
        newFilterValue = resolveSelectFilterValue(filter, filterValue);
      }
    }

    if (T.isSelectGroupFilter(filter)) {
      filterValue = filterValues.find(
        (filterValue) => T.isSelectGroupFilterValue(filterValue) && filterValue.id === filter.id,
      ) as T.SelectGroupFilterValue | undefined;

      newFilterValue = resolveSelectGroupFilterValue(filter, filterValue);
    }

    // Determine if the filter value has been updated and manage the isUpdated flag
    if (newFilterValue && hasFilterValueChanged(filterValue, newFilterValue)) {
      isUpdated = true;
      acc.push(newFilterValue);
    } else if (filterValue) {
      acc.push(filterValue);
    }

    return acc;
  }, []);

  return isUpdated ? newFilterValues : filterValues;
}

/**
 * Resolves the filter value specifically for select and tree select filters.
 * @param filter - The select or tree select filter object.
 * @param filterValue - The existing value of the filter.
 * @returns New filter value if a valid option is selected; otherwise, undefined.
 */
export function resolveSelectFilterValue(
  filter: T.SelectFilter | T.TreeSelectFilter,
  filterValue?: T.SelectFilterValue,
): T.SelectFilterValue | undefined {
  const filterOption = resolveFilterOption(filter.options ?? [], filterValue?.value);
  return filterOption ? mapFilterOptionToFilterValue(filter.id, filterOption) : undefined;
}

/**
 * Resolves the filter value specifically for select group filters.
 * Processes each filter within the group, determining the new filter code values.
 * @param filter - The select group filter object.
 * @param filterValue - The existing value of the filter.
 * @returns New filter value encapsulating filter code values if any valid option is selected; otherwise, undefined.
 */
export function resolveSelectGroupFilterValue(
  filter: T.SelectGroupFilter,
  filterValue?: T.SelectGroupFilterValue,
): T.SelectGroupFilterValue | undefined {
  const filterCodeValues: T.FilterCodeValue[] = [];

  filter.filters.forEach((f) => {
    const filterCodeValue = filterValue?.values.find((v) => v?.filterCode === f.filterCode);
    const filterOption = resolveFilterOption(f.options ?? [], filterCodeValue?.value);

    if (f.filterCode && filterOption && !isNil(filterOption.value)) {
      filterCodeValues.push({
        filterCode: f.filterCode,
        value: filterOption.value,
        __typename: 'FilterCodeValue',
      });
    }
  });

  return filterCodeValues.length
    ? createSelectGroupFilterValue(filter.id, filterCodeValues)
    : undefined;
}

/**
 * Merges two arrays of filter values, prioritizing non-zero or non-empty values for the same filter id.
 * If no non-zero value exists for a given filter id, the first value is retained as a fallback.
 *
 * @param {T.FilterValue[]} filterValues - The primary array of filter values.
 * @param {T.FilterValue[]} otherFilterValues - The secondary array of filter values to merge.
 * @returns {T.FilterValue[]} - A merged array of filter values, with non-zero values prioritized.
 */
export function mergeNonZeroFilterValues(
  filterValues: T.FilterValue[],
  otherFilterValues: T.FilterValue[],
): T.FilterValue[] {
  // Combine the filter value arrays.
  const combinedFilterValues = concat(filterValues, otherFilterValues);

  // Group combined filter values by 'id'.
  const groupedByIdFilterValues = groupBy(combinedFilterValues, 'id');

  // Map to find the first valid filter value or fallback to the first item.
  return map(groupedByIdFilterValues, (items) => items.find(isValidFilterValue) ?? items[0]);
}
