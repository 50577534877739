import * as T from '@aily/graphql-sdk/schema';
import { PageMenu, PageMenuItemProps } from '@aily/saas-core';
import { colors } from '@aily/saas-core/theme/default/colors';
import { CorrelatorIcon } from '@aily-labs/ui';
import { Popover, PopoverProps, styled } from '@mui/material';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';

import ModuleSelect from './ModuleSelect';

interface Props extends Omit<PageMenuItemProps, 'onSelect'> {
  modules: T.Module[];
  currentModule?: T.Module;
  parentExpanded?: boolean;
  onSelect?: (module: T.Module) => void;
}

const StyledPopover = styled(({ children, ...rest }: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
    transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    disableScrollLock
    {...rest}
  >
    {children}
  </Popover>
))(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: 394,
    maxHeight: 540,
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    borderRadius: 18,
    background: colors.neutrals.black,
    boxShadow: theme.shadows[20],
    padding: theme.spacing(3),
  },
}));

// currently, the only module that renders with icon is the pro module
// later, if the design system implements icons for modules we should consume all from there
const shouldRenderCorrelatorIcon = (moduleCode: T.ModuleCode) => {
  return moduleCode === T.ModuleCode.Pro;
};

const ModuleSelectPageMenuItem: React.FC<Props> = ({
  modules,
  currentModule,
  parentExpanded,
  onSelect,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
    event.currentTarget.blur();
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const handleModuleClick = useCallback(
    (module: T.Module) => () => {
      setAnchorEl(undefined);
      onSelect?.(module);
    },
    [onSelect],
  );

  const open = !!anchorEl;

  // Close the popover when the parent closes
  useEffect(() => {
    if (!parentExpanded) {
      setAnchorEl(undefined);
    }
  }, [parentExpanded]);

  // Do not display for less than 2 modules
  if (modules.length < 2) {
    return null;
  }

  return (
    <>
      <PageMenu.Item onClick={handleClick} {...rest} />
      <StyledPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <ModuleSelect>
          {modules.map((module) => (
            <ModuleSelect.Item
              key={module.id}
              active={module.id === currentModule?.id}
              onClick={handleModuleClick(module)}
              data-testid={'test-id-module-' + module.name}
            >
              {shouldRenderCorrelatorIcon(module.moduleCode) ? (
                <CorrelatorIcon height={50} width={50} />
              ) : (
                <>{module.name}</>
              )}
            </ModuleSelect.Item>
          ))}
        </ModuleSelect>
      </StyledPopover>
    </>
  );
};

export default ModuleSelectPageMenuItem;
