import * as T from '@aily/graphql-sdk/schema';
import H from '@aily/saas-core/config/highchartsConfig';
import theme from '@aily/saas-core/theme/default';
import { AiIcon } from '@aily-labs/ui';
import { Alert, alpha } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { scaleLinear } from 'd3';
import HighchartsReact from 'highcharts-react-official';
import { merge } from 'lodash-es';
import React, { useMemo } from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';
import ReactDOMServer from 'react-dom/server';

import { mapColorEnumToColor } from '../../utils';
import { ChartTooltipFormatter } from '../ChartTooltipFormatter';
import { MicroPieChart } from '../MicroPieChart';
import {
  ChartDataViewAbstractType,
  ExtendedSeries,
  ExtendedSeriesOptions,
  SeriesOptions,
  SeriesOptionsType,
} from './types';
import { usePlotLines } from './usePlotLines';
import { mapLineStyleToDashStyle, mapSeriesType, resolveSeriesColor } from './utils';

export function translateSeriesColor(color: H.ColorType, stopIndex: number = 0): string {
  if (typeof color === 'string') {
    return color;
  } else if ('stops' in color) {
    return color.stops[stopIndex][1];
  } else {
    return color.pattern.color as string;
  }
}

function getCircleSymbol(position: string, color: string) {
  const circleSymbol = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  circleSymbol.classList.add('highcharts-point');
  circleSymbol.setAttribute('transform', position);
  circleSymbol.innerHTML = ReactDOMServer.renderToString(
    <svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
      <circle
        r="3.5"
        cx="22"
        cy="6"
        fill="transparent"
        stroke={color}
        strokeWidth="1"
        strokeDasharray="4,1.5"
      />
    </svg>,
  );

  return circleSymbol;
}

function getLogoSymbol(position: string) {
  const logoSymbol = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  logoSymbol.classList.add('highcharts-logo-symbol');
  logoSymbol.setAttribute('transform', position);
  logoSymbol.innerHTML = ReactDOMServer.renderToString(<AiIcon width={13} height={13} />);

  return logoSymbol;
}

function getSeriesConfigByName(extendedSeries: ExtendedSeries) {
  const { isWhatIfChart, isPatientsChart } = (extendedSeries.chart.options as ExtendedSeriesOptions)
    .plotOptions;

  if (extendedSeries.name === 'Risk trend') {
    return {
      color: extendedSeries.color?.toString() ?? '#ff3469',
      positionCircle: 'translate(-36,9)',
      positionExtraCircle: '',
      positionSymbol: 'translate(-6,9)',
      opacity: extendedSeries.visible ? '1' : '0.4',
      showSymbol: true,
    };
  }

  if (
    extendedSeries.name === 'Enrollment trend' ||
    extendedSeries.name === 'Enrollment AI Forecast'
  ) {
    return {
      color: extendedSeries.color?.toString() ?? '#ff3469',
      positionCircle: 'translate(-38,9)',
      positionExtraCircle: '',
      positionSymbol: 'translate(-8,9)',
      opacity: extendedSeries.visible ? '1' : '0.4',
      showSymbol: true,
    };
  }

  if (
    (extendedSeries.name === 'Trend' || extendedSeries.name === 'AI Forecast') &&
    isPatientsChart
  ) {
    return {
      color: extendedSeries.color?.toString() ?? '#ff3469',
      positionCircle: 'translate(-46,9)',
      positionExtraCircle: 'translate(-36,9)',
      positionSymbol: 'translate(-6,9)',
      opacity: extendedSeries.visible ? '1' : '0.4',
      showSymbol: true,
    };
  }
  if (extendedSeries.name === 'Trend' && isWhatIfChart) {
    return {
      color: extendedSeries.color?.toString() ?? '#ff3469',
      positionCircle: 'translate(-36,9)',
      positionExtraCircle: '',
      positionSymbol: 'translate(-6,9)',
      opacity: extendedSeries.visible ? '1' : '0.4',
      showSymbol: true,
    };
  }

  if (extendedSeries.name === 'Enrollment plan') {
    return {
      color: extendedSeries.color?.toString() ?? '#ff3469',
      positionCircle: 'translate(-18,9)',
      positionExtraCircle: '',
      positionSymbol: '',
      opacity: extendedSeries.visible ? '1' : '0.4',
      showSymbol: false,
    };
  }

  return {
    color: extendedSeries.color?.toString() ?? '#ff3469',
    positionCircle: '',
    positionExtraCircle: '',
    positionSymbol: '',
    opacity: '1',
    showSymbol: false,
  };
}
function renderLegendForPatients(extendedSeries: ExtendedSeries) {
  const parent = extendedSeries.legendItem?.group?.element;
  const symbol = extendedSeries.legendItem?.symbol?.element;

  if (symbol && parent && extendedSeries.options.showTrendsDashed) {
    const { positionCircle, positionExtraCircle, positionSymbol, opacity, showSymbol } =
      getSeriesConfigByName(extendedSeries);

    if (extendedSeries.name === 'Trend' || extendedSeries.name === 'AI Forecast') {
      const circleSymbol = getCircleSymbol(
        positionCircle,
        (extendedSeries.linkedSeries as SeriesOptions[])[0].options?.color?.toString() ?? '#363d55',
      );
      const circleExtraSymbol = getCircleSymbol(
        positionExtraCircle,
        (extendedSeries.linkedSeries as SeriesOptions[])[1].options?.color?.toString() ?? '#363d55',
      );

      if (showSymbol) {
        const logoSymbol = getLogoSymbol(positionSymbol);

        if (parent?.getElementsByClassName('highcharts-logo-symbol').length == 1) {
          parent.getElementsByClassName('highcharts-logo-symbol').item(0)?.remove();
        }
        parent.appendChild(logoSymbol);
      }

      parent.appendChild(circleExtraSymbol);
      parent.replaceChild(circleSymbol, symbol);

      extendedSeries.legendItem!.symbol!.element = circleSymbol;
      parent.style.opacity = opacity;
    }
  }
}

function renderLegendForInventoryAndWhatIf(extendedSeries: ExtendedSeries) {
  const parent = extendedSeries.legendItem?.group?.element;
  const symbol = extendedSeries.legendItem?.symbol?.element;

  if (symbol && parent && extendedSeries.options.showTrendsDashed) {
    const { color, positionCircle, positionSymbol, opacity, showSymbol } =
      getSeriesConfigByName(extendedSeries);

    if (
      extendedSeries.name === 'Risk trend' ||
      extendedSeries.name === 'Enrollment trend' ||
      extendedSeries.name === 'Trend' ||
      extendedSeries.name === 'Enrollment plan'
    ) {
      const circleSymbol = getCircleSymbol(positionCircle, color);

      if (showSymbol) {
        const logoSymbol = getLogoSymbol(positionSymbol);

        if (parent?.getElementsByClassName('highcharts-logo-symbol').length == 1) {
          parent.getElementsByClassName('highcharts-logo-symbol').item(0)?.remove();
        }
        parent.appendChild(logoSymbol);
      }

      parent.replaceChild(circleSymbol, symbol);

      extendedSeries.legendItem!.symbol!.element = circleSymbol;
      parent.style.opacity = opacity;
    }
  }
}

function maybeRenderGroupedLegendSymbol(this: H.Series) {
  const extendedSeries = this as ExtendedSeries;
  const { isInventoryChart, isWhatIfChart, isPatientsChart } = (
    this.chart.options as ExtendedSeriesOptions
  ).plotOptions;

  if (isInventoryChart || isWhatIfChart) {
    return renderLegendForInventoryAndWhatIf(extendedSeries);
  }

  if (isPatientsChart) {
    return renderLegendForPatients(extendedSeries);
  }

  if (extendedSeries.linkedSeries?.length && extendedSeries.legendItem?.group?.element) {
    const parent = extendedSeries.legendItem?.group?.element;
    const symbol = extendedSeries.legendItem?.symbol?.element;

    if (symbol) {
      const newSymbol = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      newSymbol.classList.add('highcharts-point');
      newSymbol.setAttribute('transform', 'translate(-1,12)');
      newSymbol.innerHTML = ReactDOMServer.renderToString(
        <MicroPieChart
          data={Array(extendedSeries.linkedSeries.length).fill(1)}
          colors={(extendedSeries.linkedSeries as SeriesOptions[]).map((linkedSeries) =>
            this.visible ? translateSeriesColor(linkedSeries?.options?.color ?? '') : '#363d55',
          )}
          width={7}
          height={7}
        />,
      );

      // Replace the symbols
      parent.replaceChild(newSymbol, symbol);
      // Update the reference
      extendedSeries.legendItem!.symbol!.element = newSymbol;
    }
  }
}

const tooltipFormatter: H.TooltipFormatterCallbackFunction = function (tooltip: H.Tooltip) {
  return ReactDOMServer.renderToString(
    <ThemeProvider theme={theme}>
      <ChartTooltipFormatter context={this} tooltip={tooltip} />
    </ThemeProvider>,
  );
};

const tooltipPositioner: H.TooltipPositionerCallbackFunction = function (
  boxWidth: number,
  boxHeight: number,
  point: H.TooltipPositionerPointObject,
): H.PositionObject {
  const { plotLeft, plotTop, plotWidth, plotHeight } = this.chart;
  const { plotX, plotY } = point;

  const maxX = plotLeft + plotWidth - boxWidth;
  const maxY = plotTop + plotHeight - boxHeight;

  const x = Math.max(plotLeft, Math.min(maxX, plotX - boxWidth / 2));
  const y = Math.max(plotTop, Math.min(maxY, plotY - boxHeight));

  return { x, y };
};

type LengthType = 2 | 3 | 4 | 5;

function getSizeBasedOnLength(length: number): number {
  const sizeMap: Record<LengthType, number> = {
    2: 27,
    3: 30,
    4: 33,
    5: 36,
  };

  return sizeMap[length as LengthType] ?? 30;
}

const seriesDataLabelsFormatter: H.DataLabelsFormatterCallbackFunction = function () {
  if (this.point.index === this.series.points[this.series.points.length - 1].index) {
    // Set the font size scale for text lengths between 4 (e.g. "1.2B") and 6 (e.g. "627.8M") characters
    const markerFontSizeScale = scaleLinear().domain([1, 4]).range([14, 10]).clamp(true);

    const referenceFormatterY = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
      notation: 'compact',
      compactDisplay: 'short',
    }).format(this.y ?? 0);

    const shortenedNumber = Number.parseFloat(
      referenceFormatterY.substring(0, referenceFormatterY.length - 1),
    );

    const formattedY = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: shortenedNumber >= 10 ? 0 : 1,
      notation: 'compact',
      compactDisplay: 'short',
    }).format(this.y ?? 0);

    const markerBgColor = translateSeriesColor(this.color ?? 'inherit');

    const size = getSizeBasedOnLength(formattedY.length);

    return ReactDOMServer.renderToString(
      <span
        style={{
          display: 'inline-block',
          overflow: 'hidden',
          width: size,
          height: size,
          borderRadius: '50%',
          boxShadow: '0 0 3px 1px rgb(0 0 0 / 15%)',
          padding: '0 2px',
          fontWeight: 700,
          lineHeight: `${size}px`,
          textAlign: 'center',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          transform: 'translateY(50%)',
          background: markerBgColor,
          fontSize: markerFontSizeScale(formattedY.length),
        }}
      >
        {formattedY}
      </span>,
    );
  }

  return null;
};

const stringFormatter: H.AxisLabelsFormatterCallbackFunction = function () {
  // If it's the only tick on the YAxis, show the formatted value
  if (!this.axis.isXAxis && this.isFirst && this.isLast && typeof this.value === 'number') {
    const langOptions = H.getOptions().lang;
    return H.numberFormat(this.value, 0, langOptions?.decimalPoint, langOptions?.thousandsSep);
  }

  return String(this.value);
};

const percentageFormatter: H.AxisLabelsFormatterCallbackFunction = function () {
  // A number on a 0-1 scale representing a percentage is expected
  if (typeof this.value === 'number') {
    return `${this.value * 100}%`;
  }

  return this.value;
};

const chartAxisRawValueLabelFormatter: H.AxisLabelsFormatterCallbackFunction = function () {
  const value = parseInt(`${this.value}`);
  if (isNaN(value)) return `${this.value}`;

  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
};

function translateAxis(axis?: T.ChartAxis | null): H.AxisOptions | undefined {
  if (T.isChartAxisCategoryViewResult(axis)) {
    return {
      categories: axis?.categories ?? undefined,
      min: 0,
      max: axis?.categories?.length ? axis.categories.length - 1 : 0,
      labels: {
        formatter: stringFormatter,
      },
    };
  }

  if (T.isChartAxisPercentViewResult(axis)) {
    return {
      opposite: true,
      labels: {
        formatter: percentageFormatter,
      },
    };
  }

  if (T.isChartAxisValueViewResult(axis)) {
    return merge(
      {
        opposite: true,
        labels: {
          formatter: axis.hasRawDataPointValues ? chartAxisRawValueLabelFormatter : stringFormatter,
        },
      },
      axis.renderer,
    );
  }

  return undefined;
}

function resolveDataPointMarker(
  renderer: ExtendedSeriesOptions,
  data: T.ChartSeriesDataPoint[],
  position: number,
) {
  const isTriangleAndHasValue = renderer.marker?.symbol === 'triangle-down-top' && data.length > 1;

  if (!isTriangleAndHasValue) {
    return undefined;
  }

  if (position === 0 || position === data.length - 1)
    return { enabled: true, radius: 6.5, states: { hover: { enabled: false } } };
  else return { enabled: false, states: { hover: { enabled: false } } };
}

function resolveSeriesMarker(
  renderer: ExtendedSeriesOptions,
  data: T.ChartSeriesDataPoint[],
  series: T.ChartSeries,
) {
  if (renderer.marker?.symbol === 'triangle-down-top' && data.length == 1)
    return { ...renderer.marker, enabled: false };

  if (
    (series.seriesType === T.SeriesType.Area || series.seriesType === T.SeriesType.Line) &&
    data.length == 1
  )
    return { enabled: true, symbol: 'circle', radius: 4 };

  return { ...renderer.marker, enabled: false };
}

function isBadBehaviourSeries(series: H.Series) {
  return (series.options as ExtendedSeriesOptions)?.marker?.symbol === 'triangle-down-top';
}

const chartOptions: H.Options = {
  chart: {
    height: 450,
    style: {
      // @see https://ailylabs.atlassian.net/browse/CORE-505
      overflow: 'visible',
    },
  },
  plotOptions: {
    series: {
      events: {
        afterAnimate: maybeRenderGroupedLegendSymbol,
        show: maybeRenderGroupedLegendSymbol,
        hide: maybeRenderGroupedLegendSymbol,
        legendItemClick: function () {
          if (isBadBehaviourSeries(this)) {
            this.setVisible(!this.visible, false);
            return false;
          }
        },
      },
    },
    arearange: {
      // Disable mouse tracking on area range series, so that tooltips are not visible at the intersection of lines
      enableMouseTracking: false,
    },
  },
  tooltip: {
    backgroundColor: 'none',
    borderColor: 'none',
    borderRadius: 0,
    borderWidth: 0,
    shadow: false,
    shared: true,
    outside: true,
    useHTML: true,
    style: {
      zIndex: 10000,
    },
    formatter: tooltipFormatter,
    positioner: isSafari || isMobileSafari ? tooltipPositioner : undefined,
  },
};

interface ChartProps {
  dataView?: ChartDataViewAbstractType;
  defaultChartOptions?: H.Options;
  height?: number;
  customOptions?: Record<string, unknown>;
}

export const Chart: React.FC<ChartProps> = ({
  dataView,
  defaultChartOptions,
  height,
  customOptions,
}) => {
  const baseChartOptions = merge(chartOptions, dataView?.renderer, defaultChartOptions);
  const { getTextAndValueLineDescription } = usePlotLines();

  const series = useMemo(
    () =>
      (dataView?.series ?? []).map((series, index) => {
        const {
          tooltipRangeFormat,
          tooltipTitleLow,
          tooltipTitleMedian,
          tooltipTitleHigh,
          showInTooltip,
          tooltipCustomType,
        } = series;
        const data = series.data ?? [];
        const renderer = (series.renderer ?? {}) as ExtendedSeriesOptions;
        const showTrendsDashed = renderer?.plotOptions?.showTrendsDashed;
        return {
          ...renderer,
          index: renderer.dataLabelZIndex ?? index,
          legendIndex: index,
          legendSymbol: 'rectangle',
          id: String(series.id),
          name: series.name,
          type: series.seriesType ? mapSeriesType(series.seriesType) : undefined,
          linkedTo: String(series.linkedTo),
          data: data.map(
            ({ x, y }, i) =>
              ({
                x,
                y: !y?.variance ? y?.value?.raw : 0,
                low: y?.low?.raw,
                median: y?.median?.raw,
                high: y?.high?.raw,
                custom: { y, showTrendsDashed },
                marker: resolveDataPointMarker(renderer, data, i),
              }) as H.PointOptionsObject,
          ),
          showInLegend: series.showInLegend,
          custom: {
            tooltipRangeFormat,
            tooltipTitleLow,
            tooltipTitleMedian,
            tooltipTitleHigh,
            showInTooltip,
            tooltipCustomType,
          },
          color: resolveSeriesColor(series, renderer),
          borderWidth: 0,
          fillColor:
            [T.SeriesType.Area, T.SeriesType.AreaRange].includes(series.seriesType) && series.color
              ? {
                  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                  stops: [
                    [0.0, alpha(mapColorEnumToColor(series.color), 0.5)],
                    [0.8, alpha(mapColorEnumToColor(series.color), 0.05)],
                    [1, alpha(mapColorEnumToColor(series.color), 0)],
                  ],
                }
              : undefined,
          marker: resolveSeriesMarker(renderer, data, series),
          dataLabels: series.showLastPointMarker
            ? {
                enabled: true,
                allowOverlap: series.seriesType !== T.SeriesType.Spline,
                useHTML: true,
                formatter: seriesDataLabelsFormatter,
                style: {
                  color: '#ffffff',
                  textOutline: 'none',
                },
                verticalAlign: 'middle',
                y: -15,
              }
            : { enabled: false },
          dashStyle: series.lineStyle ? mapLineStyleToDashStyle(series.lineStyle) : undefined,
        };
      }) as SeriesOptionsType[],
    [dataView?.series],
  );

  const setTargetLineData = useMemo(() => {
    return getTextAndValueLineDescription(dataView?.series);
  }, [dataView?.series, getTextAndValueLineDescription]);

  const options: H.Options = useMemo(
    () =>
      ({
        ...baseChartOptions,
        xAxis: merge(chartOptions.xAxis, translateAxis(dataView?.xAxis)),
        yAxis: {
          ...merge(chartOptions.yAxis, translateAxis(dataView?.yAxis)),
          plotLines: setTargetLineData,
        },
        series,
        legend: {
          itemDistance: 40,
          labelFormatter: function (this: H.Series) {
            const { isInventoryChart, isWhatIfChart, isPatientsChart } = (
              this.chart.options as ExtendedSeriesOptions
            ).plotOptions;

            if (isInventoryChart || isWhatIfChart) {
              renderLegendForInventoryAndWhatIf(this as ExtendedSeries);
            }

            if (isPatientsChart) {
              renderLegendForPatients(this as ExtendedSeries);
            }

            return this.name;
          },
        },
        custom: {
          ...customOptions,
        },
      }) as H.Options,
    [baseChartOptions, customOptions, dataView, series, setTargetLineData],
  );

  if (!dataView?.series?.length) {
    return (
      <Alert variant="outlined" severity="error">
        No data
      </Alert>
    );
  }

  return (
    <HighchartsReact
      highcharts={H}
      containerProps={{ style: { height, minHeight: 280, maxHeight: 700 } }}
      options={options}
      data-testid="chart-highchart"
    />
  );
};
