import { Box, Button, ButtonProps, Stack, styled, Typography } from '@mui/material';
import React, { useState } from 'react';

import { type PopupProps } from '../Popup/Popup';

export interface PopupButtonProps extends ButtonProps {
  children: string;
  PopupComponent: React.ComponentType<PopupProps>;
  prefix?: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 234,
  height: 36,
  borderRadius: 36,
  padding: theme.spacing(0.875, 4),
  background: theme.palette.grey[200],
  color: theme.palette.text.primary,
  ...theme.typography.subtitle,
  '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
    background: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
}));

export const PopupButton: React.FC<PopupButtonProps> = ({
  children,
  PopupComponent,
  prefix,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <StyledButton
        data-testid={`test-popup-button-${children.toLowerCase().split(' ').join('-')}`}
        onClick={handleOpen}
        {...rest}
      >
        {prefix ? (
          <Stack direction="row" spacing={1}>
            <Typography variant="inherit" color="primary" component="span">
              {prefix}
            </Typography>
            <Typography variant="inherit" color="inherit" component="span">
              {children}
            </Typography>
          </Stack>
        ) : (
          children
        )}
      </StyledButton>
      <PopupComponent open={open} onClose={handleClose} />
    </Box>
  );
};
