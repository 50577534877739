import { useUser } from '@aily/auth-service';
import { jwtDecode } from 'jwt-decode';
import { useMemo } from 'react';

import { useAnalytics } from '../providers';
import { CommonEventProps } from '../types';

interface JwtPayload {
  identities: string;
}

export function useAnalyticsCommonEventProps(): CommonEventProps {
  const { user, userEmailDomain, isInternalUser } = useUser();
  const { env, tenant, appVersion } = useAnalytics();

  const sanofiId: string | undefined = useMemo(() => {
    try {
      if (user) {
        const decodedToken = jwtDecode<JwtPayload>(user.access_token || '');
        return decodedToken.identities ? JSON.parse(decodedToken.identities)?.userId : undefined;
      }
    } catch (e) {
      console.error('Error to parse user token:', e);
    }

    return;
  }, [user]);

  return useMemo(
    () =>
      ({
        tenant: tenant,
        env: env,
        user_id: user?.profile?.sub ?? '',
        email_domain: userEmailDomain ?? '',
        is_aily_user: isInternalUser,
        app_version: appVersion,
        release_version: appVersion,
        internal_tenant_id: sanofiId,
      }) satisfies CommonEventProps,
    [user, userEmailDomain, isInternalUser, sanofiId],
  );
}
