import { EventTrackingConfig, withEventTracking } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import React, { useCallback, useEffect, useMemo } from 'react';

import { DefaultIntercept } from '@/components/Intercept/DefaultIntercept';

export interface InterceptComponentProps {
  intercept: T.InterceptResult;
  onMount?: (intercept: T.InterceptResult) => void;
  onOk?: (intercept: T.InterceptResult) => void;
  onCancel?: (intercept: T.InterceptResult) => void;
}

const InterceptComponent: React.FC<InterceptComponentProps> = ({
  intercept,
  onMount,
  onOk,
  onCancel,
}) => {
  const imageContent = useMemo(
    () => (T.isImageResult(intercept?.media) ? intercept.media : undefined),
    [intercept],
  );

  const handleOk = useCallback(
    (intercept: T.InterceptResult) => () => {
      onOk?.(intercept);
    },
    [onOk],
  );

  const handleCancel = useCallback(
    (intercept: T.InterceptResult) => () => {
      onCancel?.(intercept);
    },
    [onCancel],
  );

  useEffect(() => {
    onMount?.(intercept);
  }, [intercept, onMount]);

  return (
    <DefaultIntercept
      key={intercept.id}
      title={intercept.title ?? ''}
      description={intercept.description ?? undefined}
      imageUrl={imageContent?.imageUrl ?? undefined}
      imageLqipUrl={imageContent?.lqipUrl ?? undefined}
      okText={intercept.linkText ?? 'Got it'}
      cancelText={intercept.cancelText ?? 'Not now'}
      onOk={handleOk(intercept)}
      onCancel={handleCancel(intercept)}
    />
  );
};

const trackingConfig: EventTrackingConfig<InterceptComponentProps> = {
  onMount: {
    eventName: 'intercept.seen',
    getEventProps: (intercept) => ({
      name: intercept.name ?? intercept.title ?? '',
      component_id: intercept.id,
      component: 'intercept',
      intent: 'see',
      item_type: 'intercept',
      url: intercept.link?.absolutePath ?? '',
      event_version: '2.0.0',
    }),
  },
  onOk: {
    eventName: 'intercept.redirected',
    getEventProps: (intercept) => ({
      name: intercept.name ?? intercept.title ?? '',
      component_id: intercept.id?.toString(),
      component: 'intercept',
      intent: 'redirect',
      component_value: 'check_out',
      item_type: 'button',
      url: intercept.link?.absolutePath ?? '',
      event_version: '2.0.0',
    }),
  },
  onCancel: {
    eventName: 'intercept.closed',
    getEventProps: (intercept) => ({
      name: intercept.name ?? intercept.title ?? '',
      component_id: intercept.id?.toString(),
      component: 'intercept',
      intent: 'close',
      component_value: 'not_now',
      item_type: 'button',
      event_version: '2.0.0',
    }),
  },
};

const TrackedInterceptComponent = withEventTracking(InterceptComponent, trackingConfig);

export default TrackedInterceptComponent;
