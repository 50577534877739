import * as T from '@aily/graphql-sdk/schema';
import { DecisionStatusEnum } from '@aily/graphql-sdk/schema';
import { Arrow, TrendIndicator, TrendText, Variance } from '@aily-labs/ui';
import { CardContentTrendIcon } from '@aily-labs/ui/beta';
import { ChipProps } from '@mui/material/Chip/Chip';
import React from 'react';

import { ModuleLogoProps } from '../components';

/**
 * Maps an enum value to its corresponding value in a default mapping, allowing overrides with a custom mapping.
 * Returns the custom mapping's value if available, otherwise defaults to the default mapping.
 */
export function mapEnumToValue<TEnum extends string, TValue>(
  enumValue: TEnum,
  defaultMap: Record<TEnum, TValue>,
  customMap?: Partial<Record<TEnum, TValue>>,
): TValue {
  return customMap?.[enumValue] ?? defaultMap[enumValue];
}

export function mapSentimentToPulseSentiment(
  sentiment: T.Sentiment,
): 'POSITIVE' | 'NEGATIVE' | 'WARNING' {
  // prettier-ignore
  const defaultMap: Record<T.Sentiment, 'POSITIVE' | 'NEGATIVE' | 'WARNING'> = {
    [T.Sentiment.SlightlyPositive]: 'POSITIVE',
    [T.Sentiment.Positive]:         'POSITIVE',
    [T.Sentiment.VeryPositive]:     'POSITIVE',
    [T.Sentiment.SlightlyNegative]: 'NEGATIVE',
    [T.Sentiment.Negative]:         'NEGATIVE',
    [T.Sentiment.VeryNegative]:     'NEGATIVE',
    [T.Sentiment.Critical]:         'NEGATIVE',
    [T.Sentiment.Neutral]:          'WARNING',
    [T.Sentiment.Warning]:          'WARNING',
  };

  return mapEnumToValue(sentiment, defaultMap);
}

export function mapSentimentToDSTrendTextSentiment(
  sentiment: T.Sentiment,
): React.ComponentProps<typeof TrendText>['sentiment'] {
  // prettier-ignore
  const defaultMap: Record<
    T.Sentiment,
    React.ComponentProps<typeof TrendText>['sentiment']
  > = {
    [T.Sentiment.SlightlyPositive]: 'POSITIVE',
    [T.Sentiment.Positive]:         'POSITIVE',
    [T.Sentiment.VeryPositive]:     'POSITIVE',
    [T.Sentiment.SlightlyNegative]: 'NEGATIVE',
    [T.Sentiment.Negative]:         'NEGATIVE',
    [T.Sentiment.VeryNegative]:     'NEGATIVE',
    [T.Sentiment.Critical]:         'NEGATIVE',
    [T.Sentiment.Neutral]:          'NEUTRAL',
    [T.Sentiment.Warning]:          'WARNING',
  };

  return mapEnumToValue(sentiment, defaultMap);
}

export function mapSentimentToTrendText(
  sentiment: T.Maybe<T.Sentiment> | undefined,
): 'POSITIVE' | 'NEGATIVE' {
  switch (sentiment) {
    case T.Sentiment.Positive:
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.VeryPositive:
      return 'POSITIVE';
    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
    case T.Sentiment.Critical:
      return 'NEGATIVE';
    default:
      return 'POSITIVE';
  }
}

export function mapSentimentToDSSentiment(
  sentiment: T.Sentiment,
): React.ComponentProps<typeof Arrow>['sentiment'] {
  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      return 'POSITIVE';
    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
    case T.Sentiment.Critical:
      return 'NEGATIVE';
    default:
      return 'NEUTRAL';
  }
}

export function mapSentimentToCardSentiment(
  sentiment: T.Sentiment,
): React.ComponentProps<typeof CardContentTrendIcon>['sentiment'] {
  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      return 'POSITIVE';
    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
    case T.Sentiment.Critical:
      return 'NEGATIVE';
    default:
      return 'POSITIVE';
  }
}

export function mapSentimentToTrendIndicatorSentiment(
  sentiment: T.Sentiment,
  customMap?: Partial<
    Record<T.Sentiment, React.ComponentProps<typeof TrendIndicator>['sentiment']>
  >,
): React.ComponentProps<typeof TrendIndicator>['sentiment'] {
  // prettier-ignore
  const defaultMap: Record<
    T.Sentiment,
    React.ComponentProps<typeof TrendIndicator>['sentiment']
  > = {
    [T.Sentiment.SlightlyPositive]: 'POSITIVE',
    [T.Sentiment.Positive]:         'POSITIVE',
    [T.Sentiment.VeryPositive]:     'POSITIVE',
    [T.Sentiment.SlightlyNegative]: 'NEGATIVE',
    [T.Sentiment.Negative]:         'NEGATIVE',
    [T.Sentiment.VeryNegative]:     'NEGATIVE',
    [T.Sentiment.Critical]:         'NEGATIVE',
    [T.Sentiment.Neutral]:          'WARNING',
    [T.Sentiment.Warning]:          'WARNING',
  };

  return mapEnumToValue(sentiment, defaultMap, customMap);
}

export function mapDirectionToDSDirection(
  direction?: T.Direction,
): React.ComponentProps<typeof Arrow>['direction'] {
  if (direction === T.Direction.Down) {
    return 'DOWN';
  }

  return 'UP';
}

export function mapSentimentToDSVarianceSentiment(
  sentiment: T.Sentiment,
): React.ComponentProps<typeof Variance>['sentiment'] {
  const sentimentMap: {
    [key in T.Sentiment]?: React.ComponentProps<typeof Variance>['sentiment'];
  } = {
    [T.Sentiment.SlightlyPositive]: 'POSITIVE',
    [T.Sentiment.Positive]: 'POSITIVE',
    [T.Sentiment.VeryPositive]: 'POSITIVE',
    [T.Sentiment.Neutral]: 'POSITIVE',
    [T.Sentiment.SlightlyNegative]: 'NEGATIVE',
    [T.Sentiment.Negative]: 'NEGATIVE',
    [T.Sentiment.VeryNegative]: 'NEGATIVE',
    [T.Sentiment.Critical]: 'NEGATIVE',
  };

  return sentiment ? sentimentMap[sentiment] || 'POSITIVE' : 'POSITIVE';
}

export function mapModuleCodeToTag(
  moduleCode: T.ModuleCode,
  customMap?: Partial<Record<T.ModuleCode, string>>,
): string {
  // prettier-ignore
  const defaultMap: Record<T.ModuleCode, string> = {
    [T.ModuleCode.Cmc]:       '.cmc',
    [T.ModuleCode.Demo]:      '.demo',
    [T.ModuleCode.Ebi]:       '.ebi',
    [T.ModuleCode.Financial]: '.fin',
    [T.ModuleCode.Gra]:       '.gra',
    [T.ModuleCode.Gtm]:       '.gtm',
    [T.ModuleCode.Mns]:       '.m&s',
    [T.ModuleCode.Ppl]:       '.ppl',
    [T.ModuleCode.Pro]:       '.pro',
    [T.ModuleCode.Qa]:        '.qa',
    [T.ModuleCode.Rnd]:       '.r&d',
    [T.ModuleCode.Spend]:     '.spend',
    [T.ModuleCode.Supply]:    '.supply',
    [T.ModuleCode.Unknown]:   '.fin',
    [T.ModuleCode.UnusedMns]: '.m&s',
  };

  return mapEnumToValue(moduleCode, defaultMap, customMap);
}

export function mapTagTypeToEmoji(
  tagType: T.TagType,
  customMap?: Partial<Record<T.TagType, string>>,
) {
  // prettier-ignore
  const defaultMap: Record<T.TagType, string> = {
    [T.TagType.Eyes]:    '👀',
    [T.TagType.Rocket]:  '🚀',
    [T.TagType.Urgent]:  '🚨',
    [T.TagType.Warning]: '⚠️'
  };

  return mapEnumToValue(tagType, defaultMap, customMap);
}

export function mapDecisionStatusToChipProps(
  decisionStatus: T.DecisionStatusEnum,
  customMap?: Partial<Record<T.DecisionStatusEnum, ChipProps>>,
): ChipProps {
  // prettier-ignore
  const defaultMap: Record<T.DecisionStatusEnum, ChipProps> = {
    [DecisionStatusEnum.Done]:       { label: 'Done', color: 'success' },
    [DecisionStatusEnum.Inprogress]: { label: 'In Progress', color: 'info' }
  };

  return mapEnumToValue(decisionStatus, defaultMap, customMap);
}

export function mapModuleCodeToLogoVariant(
  moduleCode: T.ModuleCode,
  customMap?: Partial<Record<T.ModuleCode, ModuleLogoProps['variant']>>,
): ModuleLogoProps['variant'] {
  // prettier-ignore
  const defaultMap: Record<T.ModuleCode, ModuleLogoProps['variant']> = {
    [T.ModuleCode.Cmc]:       'fin',
    [T.ModuleCode.Ebi]:       'ebi',
    [T.ModuleCode.Demo]:      'fin',
    [T.ModuleCode.Financial]: 'fin',
    [T.ModuleCode.Gra]:       'gra',
    [T.ModuleCode.Gtm]:       'gtm',
    [T.ModuleCode.Mns]:       'mns',
    [T.ModuleCode.Ppl]:       'ppl',
    [T.ModuleCode.Pro]:       'pro',
    [T.ModuleCode.Qa]:        'qa',
    [T.ModuleCode.Rnd]:       'rnd',
    [T.ModuleCode.Spend]:     'spend',
    [T.ModuleCode.Supply]:    'supply',
    [T.ModuleCode.Unknown]:   'fin',
    [T.ModuleCode.UnusedMns]: 'mns',
  };

  return mapEnumToValue(moduleCode, defaultMap, customMap);
}
