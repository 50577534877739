import { useAnalyticsTrackEvent } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import React, { useCallback } from 'react';

import { useProFiltersContext } from '../../../../contexts';
import { useEnvironment } from '../../../../providers';
import { AgentSlideshowModal } from '../AgentSlideshow';

export interface AgentModalProps {
  story?: T.Story;
  open: boolean;
  parent?: string;
  onClose: () => void;
}

export const AgentModal: React.FC<AgentModalProps> = ({ story, open, parent, onClose }) => {
  const trackEvent = useAnalyticsTrackEvent();
  const proFiltersContext = useProFiltersContext();
  const { API_URL, ENV, TENANT } = useEnvironment();
  const parsedUrl = new URL(API_URL);
  const baseAudioURL = `${parsedUrl.protocol}//${ENV}-mobile-bff.${TENANT}.aily-app.com/api/file/get-image-file`;
  const caption = parseCaption(story?.caption || '{}');
  const trackingName =
    typeof caption === 'object' ? (caption.headline ?? caption.opt_agent?.name) : caption;

  const handleClose = useCallback(() => {
    trackEvent('agent.closed', {
      screen_id: parent?.toString() || '',
      name: trackingName,
      component: 'agent',
      component_id: story?.id.toString() ?? '',
      component_type: 'agent',
      intent: 'close',
      item_type: 'button',
      event_version: '2.1.0',
      filter_name: proFiltersContext?.selectedFilters.map((filter) => filter.label) as string[],
    });
    onClose();
  }, [story, proFiltersContext, parent, onClose, trackEvent]);

  if (!story) return null;

  return (
    <AgentSlideshowModal
      story={story}
      open={open}
      onClose={handleClose}
      baseAudioURL={baseAudioURL}
      decision={story.decision ?? null}
      onBackdropClose={handleClose}
    />
  );
};

const parseCaption = (caption: string) => {
  let parsedCaption;

  try {
    parsedCaption = JSON.parse(caption || '{}');
  } catch (e) {
    parsedCaption = caption || '';
  }

  return parsedCaption;
};
