import { Reducer, useReducer } from 'react';

import { navigateToNext, navigateToPrev, navigateToSlide, updateSlideFlags } from './utils';

export interface SlideshowState {
  /**
   * The index of the current screen being displayed.
   */
  currentScreen: number;

  /**
   * The index of the current group within the current screen.
   */
  currentGroup: number;

  /**
   * The index of the current slide within the current group.
   */
  currentSlide: number;

  /**
   * The navigation structure of the slideshow, containing indexes for screens and their groups.
   * Each screen includes an array of indexes representing the groups within it.
   */
  structure: { screens: Array<{ groups: number[] }> };

  /**
   * Indicates if there's a next slide, group, or screen available for navigation.
   */
  hasNextSlide: boolean;

  /**
   * Indicates if there's a previous slide, group, or screen available for navigation.
   */
  hasPrevSlide: boolean;

  /**
   * Indicates whether the audio is enabled for the slideshow.
   */
  audioEnabled: boolean;

  /**
   * The profile of the slideshow.
   */
  slideShowProfile: SlideShowProfileType;
}

export type SlideShowProfileType = 'AGENT' | 'STUDY_DESIGNER';

export type SlideshowAction =
  | { type: 'SET_STRUCTURE'; payload: { structure: SlideshowState['structure'] } }
  | { type: 'NEXT_SLIDE' }
  | { type: 'PREV_SLIDE' }
  | { type: 'SET_PROFILE'; payload: SlideShowProfileType }
  | { type: 'GOTO_SLIDE'; payload: { index: number } }
  | { type: 'SET_AUDIO'; payload: boolean };

export const initialState: SlideshowState = {
  currentScreen: 0,
  currentGroup: 0,
  currentSlide: 0,
  structure: { screens: [] },
  hasNextSlide: false,
  hasPrevSlide: false,
  audioEnabled: true,
  slideShowProfile: 'STUDY_DESIGNER',
};

export const slideshowReducer: Reducer<SlideshowState, SlideshowAction> = (state, action) => {
  switch (action.type) {
    case 'SET_STRUCTURE': {
      const { structure } = action.payload;
      const newState = updateSlideFlags({
        ...state,
        structure,
        currentScreen: 0,
        currentGroup: 0,
        currentSlide: 0,
      });

      return { ...state, ...newState };
    }

    case 'NEXT_SLIDE': {
      const newState = navigateToNext(state);
      return { ...state, ...newState };
    }

    case 'PREV_SLIDE': {
      const newState = navigateToPrev(state);
      return { ...state, ...newState };
    }

    case 'GOTO_SLIDE': {
      const { index } = action.payload;
      const newState = navigateToSlide(state, index);
      return { ...state, ...newState };
    }

    case 'SET_AUDIO': {
      return { ...state, audioEnabled: action.payload };
    }

    case 'SET_PROFILE': {
      return { ...state, slideShowProfile: action.payload };
    }

    default:
      return state;
  }
};

export const useSlideshowReducer = () => useReducer(slideshowReducer, initialState);
