import * as T from '@aily/graphql-sdk/schema';
import { mapSentimentToColor, roundToDecimals, useProgressBar } from '@aily/saas-core';
import { colors } from '@aily/saas-core/theme/default/colors';
import { FollowIcon } from '@aily-labs/ui';
import { Box, Stack, styled, Typography } from '@mui/material';
import { motion, Transition, useAnimation } from 'framer-motion';
import React, { CSSProperties, useEffect } from 'react';

export interface AgentProgressBarProps {
  width?: string | number;
  height?: string | number;
  min?: number;
  max?: number;
  baseValue?: number;
  value?: number;
  formattedValue?: string;
  formattedValue2?: string;
  trackBorderRadius?: string | number;
  barBackground?: string;
  barStartBorderRadius?: string | number;
  barEndBorderRadius?: string | number;
  inverted?: boolean;
  description?: string;
  sentiment: T.Sentiment;
  star: boolean;
}

const AgentProgressBarContainer = styled('div')({
  position: 'relative',
  overflow: 'hidden',
});

const Track = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

const Bar = styled(motion.div)({
  position: 'absolute',
  top: 0,
  height: '100%',
});

const transition: Transition = { duration: 0.15, ease: 'easeOut' };

export const AgentProgressBar: React.FC<AgentProgressBarProps> = ({
  width = 180,
  height = 10,
  min = 0,
  max = 100,
  baseValue = 0,
  value = 0,
  // formatted values are named as in json contract, formatted to i.e. 'B', 'M', etc
  formattedValue = '0',
  formattedValue2,
  trackBorderRadius = `${height}px`,
  barBackground = colors.primary.green,
  barStartBorderRadius = 0,
  barEndBorderRadius = 10,
  inverted,
  description,
  sentiment,
  star,
}) => {
  const barControls = useAnimation();
  const {
    normalizedBaseValue,
    normalizedValue,
    barWidth: calculatedBarWidth,
  } = useProgressBar({
    min,
    max,
    baseValue,
    value,
    inverted,
  });

  // Check whether the value is before the base value
  const isBeforeBaseValue = normalizedValue < normalizedBaseValue;

  // Calculate the position style of the bar based on whether the value is before or after the base value
  const barStyle: CSSProperties = isBeforeBaseValue
    ? {
        left: `${roundToDecimals(100 - normalizedBaseValue)}%`,
        borderTopLeftRadius: barStartBorderRadius,
        borderBottomLeftRadius: barStartBorderRadius,
        borderTopRightRadius: barEndBorderRadius,
        borderBottomRightRadius: barEndBorderRadius,
      }
    : {
        right: `${normalizedBaseValue}%`,
        borderTopLeftRadius: barEndBorderRadius,
        borderBottomLeftRadius: barEndBorderRadius,
        borderTopRightRadius: barStartBorderRadius,
        borderBottomRightRadius: barStartBorderRadius,
      };

  useEffect(() => {
    barControls.start({ width: `${calculatedBarWidth}%` });
  }, [calculatedBarWidth, barControls]);

  // Render the popup progress bar and its content
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="baseline"
      borderBottom="1px solid grey"
      paddingBottom="10px"
      paddingTop="10px"
    >
      <>{description}</>
      <Box flex={1}>
        <Stack direction="row" gap="8px" alignItems="center">
          {star && <FollowIcon height={20} width={20} />}
          <Typography color={mapSentimentToColor(sentiment.toUpperCase() as T.Sentiment)}>
            {formattedValue}
          </Typography>
          <Box flex={1}>
            <AgentProgressBarContainer sx={{ width, height }}>
              <Track
                sx={{
                  borderRadius: trackBorderRadius,
                  overflow: 'hidden',
                }}
              >
                <Bar
                  sx={{ background: barBackground, opacity: '20%', ...barStyle }}
                  initial={{ width: '0%' }}
                  transition={transition}
                  animate={barControls}
                />
              </Track>
            </AgentProgressBarContainer>
          </Box>
        </Stack>
      </Box>
      {formattedValue2 && <Box paddingLeft={1}>{formattedValue2}</Box>}
    </Stack>
  );
};
