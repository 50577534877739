import { Box, BoxProps, Slider, styled, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';

export interface DiscreteSliderProps<TValue> {
  label?: string;
  selectedValue: number;
  values: string[];
  onChange: (newValue: TValue) => void;
  disabled?: boolean;
  BoxProps?: BoxProps;
  isDisabled?: boolean;
  testID?: string;
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled?: boolean;
}>(({ theme, disabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginVertical: 0,
  marginHorizontal: 10,
  color: theme.palette.text.primary,
  ...(disabled && { color: theme.palette.text.secondary }),
}));

const StyledSlider = styled(Slider)(({ theme, disabled }) => ({
  '& .MuiSlider-rail': {
    opacity: 1,
    height: 6,
    borderRadius: 6,
    backgroundColor: `var(--color-neutral-grey-dark, ${theme.palette.grey[900]})`,
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
    ...(disabled && { backgroundColor: theme.palette.background.paper }),
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 'bold',
    top: 50,
    zIndex: 300,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: '#fff',
      ...theme.applyStyles('dark', {
        color: '#fff',
      }),
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 5,
  },
  '& .MuiSlider-thumb': {
    width: 16,
    height: 16,
    backgroundColor: theme.palette.success.main,
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
    ...(disabled && { backgroundColor: theme.palette.grey[100] }),
    '&, &:hover, &.Mui-focusVisible, &.Mui-active': {
      boxShadow: `0 0 25px 0 ${theme.palette.success.main}`,
      ...(disabled && { boxShadow: 'none' }),
    },
  },

  '& .MuiSlider-mark': {
    backgroundColor: `var(--color-neutral-grey-dark, ${theme.palette.grey[900]})`,
    height: 18,
    width: 18,
    borderRadius: '50%',
    marginTop: -1,
    boxShadow: `0 0 5px 0 ${theme.palette.grey[900]}`,
    ...(disabled && { boxShadow: 'none' }),
  },

  '& .MuiSlider-markActive': {
    opacity: 0,
    backgroundColor: theme.palette.primary.dark,
  },

  '&.MuiSlider-dragging .MuiSlider-thumb': {
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
}));

export const DiscreteSlider = <TValue,>({
  label,
  selectedValue,
  values,
  onChange,
  disabled,
  BoxProps,
  isDisabled,
  testID = 'slider-select',
}: DiscreteSliderProps<TValue>) => {
  const transformValues = useCallback(() => {
    const minValue = 0;
    const maxValue = values.length - 1;
    const interval = (maxValue - minValue) / (values.length - 1);
    const trackMarks = values.map((_, index) => ({ value: minValue + interval * index }));
    return { minValue, maxValue, trackMarks, interval };
  }, [values]);

  const { minValue, maxValue } = transformValues();

  const completeSliding = useCallback(
    // @ts-expect-error generic value passed and somewhere coerced
    (_event, val) => {
      // @ts-expect-error generic value passed and somewhere coerced
      onChange({ selectedValue: val, label: values[val], name: label });
    },
    [onChange],
  );

  const valueLabelFormat = useCallback(
    (value: number) => {
      return values[value] || value;
    },
    [values],
  );

  useEffect(() => {
    completeSliding(values, selectedValue);
  }, []);

  return (
    <StyledBox disabled={disabled} data-testid={testID} {...BoxProps}>
      {!!label && (
        <Typography variant="body" color="inherit" data-testid="slider-select__label">
          {label}
        </Typography>
      )}
      <StyledSlider
        value={isDisabled ? 0.5 : selectedValue}
        min={isDisabled ? 0 : minValue}
        max={isDisabled ? 1 : maxValue}
        marks={true}
        track={false}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
        onChangeCommitted={completeSliding}
        data-testid="slider-select__slider"
        disabled={isDisabled}
      />
    </StyledBox>
  );
};
