import * as T from '@aily/graphql-sdk/schema';
import { Tab, Tabs } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { createPath, matchPath, Navigate, useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { FilterSearchParams } from '../../constants';
import {
  useNavigateSearch,
  usePersistentParams,
  usePriorityId,
  useSearchParams,
} from '../../hooks';
import { normalizeAbsolutePath } from '../../utils';

export interface NavigationTabsProps {
  component: T.NavigationTabsComponent;
}

const persistentParams: string[] = [
  FilterSearchParams.PRIORITY_ID,
  FilterSearchParams.DIMENSION_ID,
];

const isEnrollmentOrPatientsOrSites = (pathname: string) =>
  pathname.endsWith('/enrollment') ||
  pathname.endsWith('/enrollment/chart') ||
  pathname.endsWith('/patients') ||
  pathname.endsWith('/patients/chart') ||
  pathname.endsWith('/sites') ||
  pathname.endsWith('/sites/chart');

const NavigationTabs: React.FC<NavigationTabsProps> = ({ component }) => {
  const { tabs } = component;
  const { pathname } = useLocation();
  const searchParams = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const priorityId = usePriorityId();
  const previousPriorityId = usePrevious(priorityId);

  const newSearchParams = usePersistentParams(persistentParams);

  // Find the tab with a matching path based on the current pathname
  const matchedPath = useMemo(() => {
    const foundTab = tabs?.find(
      ({ path }) => path && path !== '/' && matchPath(`${normalizeAbsolutePath(path)}/*`, pathname),
    );

    return foundTab?.path;
  }, [tabs, pathname]);

  // This hack is needed until Priority and Dimension filter fetching is fixed
  const isRndGnoMilestonesTable = useMemo(() => {
    return (
      pathname === '/web/rnd/trials/gnomilestonestimeline' ||
      pathname === '/web/rnd/trials/gnomilestones'
    );
  }, [pathname]);

  // Handle a change in the selected tab by navigating to the new pathname
  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newPath: string) => {
      /**
       * Synchronization of the drillId parameter between the "Patients" and "Sitemap" tabs
       * @see https://ailylabs.atlassian.net/browse/PROD20-4652
       */
      const drillId = searchParams.get(FilterSearchParams.DRILL_ID);
      if (isEnrollmentOrPatientsOrSites(pathname) && isEnrollmentOrPatientsOrSites(newPath)) {
        if (drillId) {
          newSearchParams.set(FilterSearchParams.DRILL_ID, drillId);
        }
      }

      navigateSearch(normalizeAbsolutePath(newPath), newSearchParams);
    },
    [pathname, searchParams, newSearchParams, navigateSearch],
  );

  // If no matching tab is found, navigate to the first tab in the list
  // This needs to be revisited to find a cleaner solution
  if (
    (isRndGnoMilestonesTable && !matchedPath && !previousPriorityId) ||
    (!matchedPath && !isRndGnoMilestonesTable)
  ) {
    return (
      <Navigate
        to={createPath({
          pathname: tabs?.[0]?.path ? normalizeAbsolutePath(tabs[0].path) : '/',
          search: newSearchParams.toString(),
        })}
        replace
      />
    );
  }

  return (
    <Tabs
      value={matchedPath}
      onChange={handleChange}
      data-testid="NavigationTabs"
      sx={{ height: 48 }}
      variant="scrollable"
    >
      {tabs?.map(
        ({ path, title }) =>
          !!path && <Tab key={path} value={path} label={title} data-testid="TabButton" />,
      )}
    </Tabs>
  );
};

export default React.memo(NavigationTabs);
