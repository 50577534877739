import { Box, BoxProps, styled } from '@mui/material';
import React, { FC } from 'react';

export interface RecommendationsBoxProps extends BoxProps {
  smaller?: boolean;
}

const StyledBox = styled(Box)(() => ({
  paddingTop: 20,
  paddingBottom: 20,
  marginRight: 5,
}));

const StyledSmallerBox = styled(Box)(() => ({
  paddingTop: 10,
  paddingBottom: 10,
  marginRight: 5,
  width: '50%',
}));

export const RecommendationsBox: FC<RecommendationsBoxProps> = ({ children, smaller, ...rest }) =>
  smaller ? (
    <StyledSmallerBox {...rest}>{children}</StyledSmallerBox>
  ) : (
    <StyledBox {...rest}>{children}</StyledBox>
  );
