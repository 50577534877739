/**
 * Capitalizes the first letter of the first element and removes the period from the last element if it exists.
 *
 * This function performs two main operations:
 * 1. If the first element is a string, it capitalizes the first letter.
 * 2. If the last element is a string and ends with a period, it removes the period.
 *
 * @param elements - An array of JSX elements or strings to be formatted.
 * @returns A new array with the formatted elements.
 */
export const capitalizeFirstAndRemoveLastPeriod = (
  elements: Array<JSX.Element | string>,
): Array<JSX.Element | string> => {
  // Return the array as is if it's empty
  if (elements.length === 0) return elements;

  // Check and modify the first element if it's a string
  const firstElement = elements[0];
  if (typeof firstElement === 'string') {
    elements[0] = `${firstElement.charAt(0).toUpperCase()}${firstElement.slice(1)}`;
  }

  // Check and modify the last element if it's a string and ends with a period
  const lastElement = elements[elements.length - 1];
  if (typeof lastElement === 'string' && lastElement.endsWith('.')) {
    elements[elements.length - 1] = lastElement.slice(0, -1);
  }

  // Return the modified array
  return elements;
};

/**
 * Converts the first letter of a string to lowercase.
 *
 * This function takes a string and returns a new string with the first letter converted to lowercase.
 *
 * @param str - The string to be formatted.
 * @returns A new string with the first letter in lowercase.
 */
export const lowercaseFirstLetter = (str: string): string => {
  if (!str) return str;
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
};
