import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren, useEffect, useState } from 'react';
import {
  AuthProvider as OIDCAuthProvider,
  AuthProviderProps,
  hasAuthParams,
  useAuth,
} from 'react-oidc-context';

let userManager: UserManager | undefined;

export const initAuth = (userManagerSettings: UserManagerSettings) => {
  userManager = new UserManager({
    response_type: 'code',
    automaticSilentRenew: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    ...userManagerSettings,
  });
};

const getUserManager = () => {
  return userManager;
};

const getStorageKey = (userManagerSettings: UserManagerSettings | undefined) => {
  if (userManagerSettings === undefined) {
    return '';
  }

  return `oidc.user:${userManagerSettings.authority}:${userManagerSettings.client_id}`;
};

export const getCurrentUser = (): User | null => {
  const oidcStorage = localStorage.getItem(getStorageKey(userManager?.settings));

  return oidcStorage ? User.fromStorageString(oidcStorage) : null;
};

export const getToken = (): string | null => {
  const user = getCurrentUser();

  return user?.access_token ?? null;
};

interface NavigationState {
  /**
   * The URL to redirect the user to after login.
   */
  returnUrl?: string;
}

export const signIn = async (): Promise<void> => {
  try {
    const user = await getUserManager()?.signinSilent();

    if (user) {
      window.location.reload();
    }
  } catch (e) {
    await getUserManager()?.signinRedirect({ state: { returnUrl: window.location.href } });
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await getUserManager()?.removeUser();
    await getUserManager()?.signoutRedirect();
    await getUserManager()?.clearStaleState();
  } catch (e) {
    console.error(e);
  }
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const oidcConfig: AuthProviderProps = {
    userManager: getUserManager(),
    onSigninCallback: (user) => {
      const navigationState = user && user.state ? (user.state as NavigationState) : null;
      // Redirect to returnUrl if available, else to default route
      if (navigationState?.returnUrl) {
        window.location.href = navigationState.returnUrl;
      } else {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    },
  };

  return <OIDCAuthProvider {...oidcConfig}>{children}</OIDCAuthProvider>;
};

export const useAutoSignIn = () => {
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const { activeNavigator, isAuthenticated, isLoading, events, signinRedirect, signinSilent } =
    useAuth();

  // Automatically sign in the user if they are not already signed in
  useEffect(() => {
    if (!hasAuthParams() && !activeNavigator && !isAuthenticated && !isLoading && !hasTriedSignin) {
      signinRedirect({ state: { returnUrl: window.location.href } });
      setHasTriedSignin(true);
    }
  }, [activeNavigator, isAuthenticated, isLoading, signinRedirect, hasTriedSignin]);

  // Silently sign in the user when the token is about to expire
  useEffect(() => {
    const unsubscribe = events.addAccessTokenExpiring(() => {
      signinSilent();
    });

    return () => {
      unsubscribe();
    };
  }, [events, signinSilent]);
};

export * from './hooks';
export type * from 'oidc-client-ts';
export type * from 'react-oidc-context';
export { useAuth } from 'react-oidc-context';
