import { CloseIcon, EditIcon, HStack, PressableOpacity } from '@aily-labs/ui';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface HeaderComponentProps {
  onEditGoals?(): void;
  onClose?(): void;
}

const HeaderComponent = ({ onEditGoals, onClose }: HeaderComponentProps) => {
  const navigate = useNavigate();

  const handleBackHome = useCallback(() => {
    navigate('/');
  }, []);

  return (
    <HStack justifyContent="flex-end" alignItems="center" gap={15} padding={20} zIndex={100}>
      {onEditGoals && (
        <PressableOpacity testID={`test-edit-goals`} onPress={onEditGoals}>
          <EditIcon color="white" width={24} height={24} />
        </PressableOpacity>
      )}
      <PressableOpacity onPress={onClose ? onClose : handleBackHome}>
        <CloseIcon color="white" width={20} height={20} />
      </PressableOpacity>
    </HStack>
  );
};

export default HeaderComponent;
