import { Box } from '@mui/material';
import React from 'react';

import { Slideshow, SlideshowProps } from '../../../../components/Slideshow';

export interface AgentSlideshowProps extends SlideshowProps {
  hideSoundControl?: boolean;
}

export const AgentSlideshow: React.FC<AgentSlideshowProps> = ({ children, ...rest }) => {
  return (
    <Box position="relative">
      <Slideshow profile="AGENT" sx={{ paddingTop: 0 }} {...rest}>
        {children}
      </Slideshow>
    </Box>
  );
};
