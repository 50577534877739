import { gql } from '@apollo/client';

import type {
  DataViewFields_RndHomeDialsDataView_Fragment,
  FilterComponentFieldsFragment,
  FilterValueFields_MultiSelectFilterValue_Fragment,
  FilterValueFields_SelectFilterValue_Fragment,
  FilterValueFields_SelectGroupFilterValue_Fragment,
  RecommendationItemFieldsFragment,
  TableComponentFieldsFragment,
} from '../../../core/__generated__/fragments';
import {
  DataViewFieldsFragmentDoc,
  FilterComponentFieldsFragmentDoc,
  FilterValueFieldsFragmentDoc,
  RecommendationItemFieldsFragmentDoc,
  TableComponentFieldsFragmentDoc,
} from '../../../core/__generated__/fragments';
import type * as T from '../../../schema/__generated__/types';
import type { DialItemFieldsFragment } from '../../gra/__generated__/fragments';
import { DialItemFieldsFragmentDoc } from '../../gra/__generated__/fragments';
export type RndInventoryRecommendationsListFieldsFragment = {
  __typename?: 'RndInventoryRecommendationsList';
  title?: string | null;
  items?: Array<
    { __typename?: 'RndInventoryRecommendationItem' } & RndInventoryRecommendationItemFieldsFragment
  > | null;
};

export type RndInventoryRecommendationsDataViewFieldsFragment = {
  __typename?: 'RndInventoryRecommendationsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  inventoryRecommendationsList?:
    | ({
        __typename?: 'RndInventoryRecommendationsList';
      } & RndInventoryRecommendationsListFieldsFragment)
    | null;
  link?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type RndInventoryRecommendationsComponentFieldsFragment = {
  __typename?: 'RndInventoryRecommendationsComponent';
  id: string;
  componentType: T.ComponentType;
  viewType: T.ViewType;
  code: string;
  icon: T.ComponentIcon;
  filters?: Array<{ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment> | null;
};

export type RndInventoryRecommendationItemFieldsFragment = {
  __typename?: 'RndInventoryRecommendationItem';
  recommendationItem?:
    | ({ __typename?: 'RecommendationItem' } & RecommendationItemFieldsFragment)
    | null;
};

export type RndInventoryRecommendationsBreakdownDataViewFieldsFragment = {
  __typename?: 'RndInventoryRecommendationsBreakdownDataView';
  id: string;
  dataViewType: T.DataViewType;
  pageHeader?: string | null;
  pageSubHeader?: string | null;
  breakdownTabs?: Array<
    ({ __typename?: 'TableComponent' } & TableComponentFieldsFragment) | null
  > | null;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type RndInventoryRecommendationsBreakdownComponentFieldsFragment = {
  __typename?: 'RndInventoryRecommendationsBreakdownComponent';
  id: string;
  componentType: T.ComponentType;
  viewType: T.ViewType;
  code: string;
  icon: T.ComponentIcon;
  filters?: Array<{ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment> | null;
};

export type RndHomeDialsDataViewFieldsFragment = {
  __typename?: 'RndHomeDialsDataView';
  title: string;
  annotation?: string | null;
  annotationDate?: string | null;
  items?: Array<{ __typename?: 'RndDialItem' } & RndHomeDialFieldsFragment> | null;
} & DataViewFields_RndHomeDialsDataView_Fragment;

export type RndHomeDialFieldsFragment = {
  __typename?: 'RndDialItem';
  dialItem?: ({ __typename?: 'DialItem' } & DialItemFieldsFragment) | null;
};

export type UserSettingFieldsFragment = {
  __typename?: 'UserSetting';
  key: string;
  label: string;
  defaultValue?: number | null;
  userValue?: number | null;
  options: Array<{ __typename?: 'SettingOption' } & SettingOptionFieldsFragment>;
};

export type SettingOptionFieldsFragment = {
  __typename?: 'SettingOption';
  label: string;
  value: number;
};

export type MetaDataFieldsFragment = {
  __typename?: 'MetaData';
  key?: string | null;
  label?: string | null;
  value?: string | null;
};

export type TextLinkFieldsFragment = {
  __typename?: 'TextLinkResult';
  value?: string | null;
  label?: string | null;
  subLabels?: Array<string> | null;
  linkDirection?: T.LinkDirection | null;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export type LinkFieldsFragment = {
  __typename?: 'Link';
  pageId?: string | null;
  absolutePath?: string | null;
  drillId?: number | null;
  drillIds?: Array<number> | null;
  target?: T.LinkTargetType | null;
  plailistId?: string | null;
  previousPriorityId?: number | null;
  filters?: Array<
    | ({
        __typename?: 'MultiSelectFilterValue';
      } & FilterValueFields_MultiSelectFilterValue_Fragment)
    | ({ __typename?: 'SelectFilterValue' } & FilterValueFields_SelectFilterValue_Fragment)
    | ({
        __typename?: 'SelectGroupFilterValue';
      } & FilterValueFields_SelectGroupFilterValue_Fragment)
  > | null;
};

export type ComponentFields_ButtonComponent_Fragment = {
  __typename?: 'ButtonComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_CardsComponent_Fragment = {
  __typename?: 'CardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_CashFlowKpiComponent_Fragment = {
  __typename?: 'CashFlowKpiComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ChartComponent_Fragment = {
  __typename?: 'ChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ColumnLayoutComponent_Fragment = {
  __typename?: 'ColumnLayoutComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ComponentBase_Fragment = {
  __typename?: 'ComponentBase';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_DemoHomeChartComponent_Fragment = {
  __typename?: 'DemoHomeChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_DemoHomeMicroChartComponent_Fragment = {
  __typename?: 'DemoHomeMicroChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_DialsComponent_Fragment = {
  __typename?: 'DialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeCategoriesDialsComponent_Fragment = {
  __typename?: 'EbiHomeCategoriesDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsChartComponent_Fragment = {
  __typename?: 'EbiHomeDetailsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment = {
  __typename?: 'EbiHomeDetailsChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment = {
  __typename?: 'EbiHomeDetailsDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment = {
  __typename?: 'EbiHomeDetailsDriversChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsDriversComponent_Fragment = {
  __typename?: 'EbiHomeDetailsDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment = {
  __typename?: 'EbiHomeDetailsRecommendationsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FilterComponent_Fragment = {
  __typename?: 'FilterComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FiltersComponent_Fragment = {
  __typename?: 'FiltersComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment = {
  __typename?: 'FinGtnOpexAiRecommendationComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FinHomePageCardsComponent_Fragment = {
  __typename?: 'FinHomePageCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FinPnlAiRecommendationComponent_Fragment = {
  __typename?: 'FinPnlAiRecommendationComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment = {
  __typename?: 'GraFacilitatedPathwaysTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraHomeCardsComponent_Fragment = {
  __typename?: 'GraHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraHomeDialsComponent_Fragment = {
  __typename?: 'GraHomeDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment = {
  __typename?: 'GraRecommendedPathwaysDetailsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraRecommendedPathwaysTableComponent_Fragment = {
  __typename?: 'GraRecommendedPathwaysTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GridComponent_Fragment = {
  __typename?: 'GridComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmHomeCardsComponent_Fragment = {
  __typename?: 'GtmHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmHomeDialComponent_Fragment = {
  __typename?: 'GtmHomeDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmHomepageCardsComponent_Fragment = {
  __typename?: 'GtmHomepageCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmImpactRecommendationComponent_Fragment = {
  __typename?: 'GtmImpactRecommendationComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmImpactTableComponent_Fragment = {
  __typename?: 'GtmImpactTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmImpactTableHeaderComponent_Fragment = {
  __typename?: 'GtmImpactTableHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmInteractionsChartComponent_Fragment = {
  __typename?: 'GtmInteractionsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmInteractionsTableComponent_Fragment = {
  __typename?: 'GtmInteractionsTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDetailsChartComponent_Fragment = {
  __typename?: 'GtmPulseDetailsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment = {
  __typename?: 'GtmPulseDetailsChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDetailsDriversComponent_Fragment = {
  __typename?: 'GtmPulseDetailsDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDialComponent_Fragment = {
  __typename?: 'GtmPulseDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseLeaderboardListComponent_Fragment = {
  __typename?: 'GtmPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment = {
  __typename?: 'GtmPulseLeaderboardPositionComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseRecommendedActionsComponent_Fragment = {
  __typename?: 'GtmPulseRecommendedActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmReachTableComponent_Fragment = {
  __typename?: 'GtmReachTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmResourceAllocationTableComponent_Fragment = {
  __typename?: 'GtmResourceAllocationTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_HeaderRowComponent_Fragment = {
  __typename?: 'HeaderRowComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_HeaderSimpleComponent_Fragment = {
  __typename?: 'HeaderSimpleComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_KpiComponent_Fragment = {
  __typename?: 'KpiComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ListComponent_Fragment = {
  __typename?: 'ListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MarkdownComponent_Fragment = {
  __typename?: 'MarkdownComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MicroChartComponent_Fragment = {
  __typename?: 'MicroChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MnsDeviationsDetailsComponent_Fragment = {
  __typename?: 'MnsDeviationsDetailsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MnsDeviationsDialComponent_Fragment = {
  __typename?: 'MnsDeviationsDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_NavigationTabsComponent_Fragment = {
  __typename?: 'NavigationTabsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PlailistComponent_Fragment = {
  __typename?: 'PlailistComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesBreakdownChartComponent_Fragment = {
  __typename?: 'PnlRebatesBreakdownChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment = {
  __typename?: 'PnlRebatesBreakdownChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesBreakdownTableComponent_Fragment = {
  __typename?: 'PnlRebatesBreakdownTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesInsightsCardsComponent_Fragment = {
  __typename?: 'PnlRebatesInsightsCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment = {
  __typename?: 'PnlRebatesInsightsHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeCardsComponent_Fragment = {
  __typename?: 'PplHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeCategoriesDialsComponent_Fragment = {
  __typename?: 'PplHomeCategoriesDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsChartComponent_Fragment = {
  __typename?: 'PplHomeDetailsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment = {
  __typename?: 'PplHomeDetailsChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsDriversChartComponent_Fragment = {
  __typename?: 'PplHomeDetailsDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment = {
  __typename?: 'PplHomeDetailsDriversChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsDriversComponent_Fragment = {
  __typename?: 'PplHomeDetailsDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment = {
  __typename?: 'PplHomeDetailsRecommendationsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDialComponent_Fragment = {
  __typename?: 'PplHomeDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ProAgentDetailsComponent_Fragment = {
  __typename?: 'ProAgentDetailsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ProDailyAgentComponent_Fragment = {
  __typename?: 'ProDailyAgentComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ProHomeFeedComponent_Fragment = {
  __typename?: 'ProHomeFeedComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RecommendationsActionsComponent_Fragment = {
  __typename?: 'RecommendationsActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RecommendationsBreakdownComponent_Fragment = {
  __typename?: 'RecommendationsBreakdownComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RecommenderComponent_Fragment = {
  __typename?: 'RecommenderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndHomeDialsComponent_Fragment = {
  __typename?: 'RndHomeDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment = {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment = {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment = {
  __typename?: 'RndInventoryRecommendationsBreakdownComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInventoryRecommendationsComponent_Fragment = {
  __typename?: 'RndInventoryRecommendationsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInventoryRootCausesComponent_Fragment = {
  __typename?: 'RndInventoryRootCausesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndStudyDesignFormComponent_Fragment = {
  __typename?: 'RndStudyDesignFormComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment = {
  __typename?: 'RndStudyDesignsSiteDistributionTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndStudyDesignsTableComponent_Fragment = {
  __typename?: 'RndStudyDesignsTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsAiPosDriversComponent_Fragment = {
  __typename?: 'RndTrialsAiPosDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsKpiCategoriesComponent_Fragment = {
  __typename?: 'RndTrialsKpiCategoriesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsKpiDetailTableComponent_Fragment = {
  __typename?: 'RndTrialsKpiDetailTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment = {
  __typename?: 'RndTrialsRndInvestmentsPnlTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndValueOverviewChartComponent_Fragment = {
  __typename?: 'RndValueOverviewChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndValueOverviewChartHeaderComponent_Fragment = {
  __typename?: 'RndValueOverviewChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseDialComponent_Fragment = {
  __typename?: 'SpendCmoPulseDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseDriversChartComponent_Fragment = {
  __typename?: 'SpendCmoPulseDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseDriversListComponent_Fragment = {
  __typename?: 'SpendCmoPulseDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment = {
  __typename?: 'SpendCmoPulseKeyDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment = {
  __typename?: 'SpendCmoPulseKeyDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment = {
  __typename?: 'SpendCmoPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment = {
  __typename?: 'SpendCmoPulseLeaderboardPositionComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment = {
  __typename?: 'SpendCmoPulseRecommendedActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseDialComponent_Fragment = {
  __typename?: 'SpendGlocalPulseDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment = {
  __typename?: 'SpendGlocalPulseDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseDriversListComponent_Fragment = {
  __typename?: 'SpendGlocalPulseDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment = {
  __typename?: 'SpendGlocalPulseKeyDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment = {
  __typename?: 'SpendGlocalPulseKeyDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment = {
  __typename?: 'SpendGlocalPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment = {
  __typename?: 'SpendGlocalPulseLeaderboardPositionComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment = {
  __typename?: 'SpendGlocalPulseRecommendedActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendHomeCardsComponent_Fragment = {
  __typename?: 'SpendHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendHomeDialComponent_Fragment = {
  __typename?: 'SpendHomeDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendProcurementChartComponent_Fragment = {
  __typename?: 'SpendProcurementChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendProcurementTableComponent_Fragment = {
  __typename?: 'SpendProcurementTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendTrendingComponent_Fragment = {
  __typename?: 'SpendTrendingComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_StoryListComponent_Fragment = {
  __typename?: 'StoryListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SummaryInfoComponent_Fragment = {
  __typename?: 'SummaryInfoComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyHomeCardsComponent_Fragment = {
  __typename?: 'SupplyHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyHomeDialsComponent_Fragment = {
  __typename?: 'SupplyHomeDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment = {
  __typename?: 'SupplyLowInventoryRiskRootCausesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyPulseLeaderboardListComponent_Fragment = {
  __typename?: 'SupplyPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyShortDatedRootCausesComponent_Fragment = {
  __typename?: 'SupplyShortDatedRootCausesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupportComponent_Fragment = {
  __typename?: 'SupportComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_TableComponent_Fragment = {
  __typename?: 'TableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_TableSwitcherComponent_Fragment = {
  __typename?: 'TableSwitcherComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_TimelineComponent_Fragment = {
  __typename?: 'TimelineComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_UserAnalyticsComponent_Fragment = {
  __typename?: 'UserAnalyticsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_UserPrioritiesComponent_Fragment = {
  __typename?: 'UserPrioritiesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_VideoComponent_Fragment = {
  __typename?: 'VideoComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFieldsFragment =
  | ComponentFields_ButtonComponent_Fragment
  | ComponentFields_CardsComponent_Fragment
  | ComponentFields_CashFlowKpiComponent_Fragment
  | ComponentFields_ChartComponent_Fragment
  | ComponentFields_ColumnLayoutComponent_Fragment
  | ComponentFields_ComponentBase_Fragment
  | ComponentFields_DemoHomeChartComponent_Fragment
  | ComponentFields_DemoHomeMicroChartComponent_Fragment
  | ComponentFields_DialsComponent_Fragment
  | ComponentFields_EbiHomeCategoriesDialsComponent_Fragment
  | ComponentFields_EbiHomeDetailsChartComponent_Fragment
  | ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment
  | ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment
  | ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment
  | ComponentFields_EbiHomeDetailsDriversComponent_Fragment
  | ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment
  | ComponentFields_FilterComponent_Fragment
  | ComponentFields_FiltersComponent_Fragment
  | ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment
  | ComponentFields_FinHomePageCardsComponent_Fragment
  | ComponentFields_FinPnlAiRecommendationComponent_Fragment
  | ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment
  | ComponentFields_GraHomeCardsComponent_Fragment
  | ComponentFields_GraHomeDialsComponent_Fragment
  | ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment
  | ComponentFields_GraRecommendedPathwaysTableComponent_Fragment
  | ComponentFields_GridComponent_Fragment
  | ComponentFields_GtmHomeCardsComponent_Fragment
  | ComponentFields_GtmHomeDialComponent_Fragment
  | ComponentFields_GtmHomepageCardsComponent_Fragment
  | ComponentFields_GtmImpactRecommendationComponent_Fragment
  | ComponentFields_GtmImpactTableComponent_Fragment
  | ComponentFields_GtmImpactTableHeaderComponent_Fragment
  | ComponentFields_GtmInteractionsChartComponent_Fragment
  | ComponentFields_GtmInteractionsTableComponent_Fragment
  | ComponentFields_GtmPulseDetailsChartComponent_Fragment
  | ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment
  | ComponentFields_GtmPulseDetailsDriversComponent_Fragment
  | ComponentFields_GtmPulseDialComponent_Fragment
  | ComponentFields_GtmPulseLeaderboardListComponent_Fragment
  | ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment
  | ComponentFields_GtmPulseRecommendedActionsComponent_Fragment
  | ComponentFields_GtmReachTableComponent_Fragment
  | ComponentFields_GtmResourceAllocationTableComponent_Fragment
  | ComponentFields_HeaderRowComponent_Fragment
  | ComponentFields_HeaderSimpleComponent_Fragment
  | ComponentFields_KpiComponent_Fragment
  | ComponentFields_ListComponent_Fragment
  | ComponentFields_MarkdownComponent_Fragment
  | ComponentFields_MicroChartComponent_Fragment
  | ComponentFields_MnsDeviationsDetailsComponent_Fragment
  | ComponentFields_MnsDeviationsDialComponent_Fragment
  | ComponentFields_NavigationTabsComponent_Fragment
  | ComponentFields_PlailistComponent_Fragment
  | ComponentFields_PnlRebatesBreakdownChartComponent_Fragment
  | ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment
  | ComponentFields_PnlRebatesBreakdownTableComponent_Fragment
  | ComponentFields_PnlRebatesInsightsCardsComponent_Fragment
  | ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment
  | ComponentFields_PplHomeCardsComponent_Fragment
  | ComponentFields_PplHomeCategoriesDialsComponent_Fragment
  | ComponentFields_PplHomeDetailsChartComponent_Fragment
  | ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment
  | ComponentFields_PplHomeDetailsDriversChartComponent_Fragment
  | ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment
  | ComponentFields_PplHomeDetailsDriversComponent_Fragment
  | ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment
  | ComponentFields_PplHomeDialComponent_Fragment
  | ComponentFields_ProAgentDetailsComponent_Fragment
  | ComponentFields_ProDailyAgentComponent_Fragment
  | ComponentFields_ProHomeFeedComponent_Fragment
  | ComponentFields_RecommendationsActionsComponent_Fragment
  | ComponentFields_RecommendationsBreakdownComponent_Fragment
  | ComponentFields_RecommenderComponent_Fragment
  | ComponentFields_RndHomeDialsComponent_Fragment
  | ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment
  | ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment
  | ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment
  | ComponentFields_RndInventoryRecommendationsComponent_Fragment
  | ComponentFields_RndInventoryRootCausesComponent_Fragment
  | ComponentFields_RndStudyDesignFormComponent_Fragment
  | ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment
  | ComponentFields_RndStudyDesignsTableComponent_Fragment
  | ComponentFields_RndTrialsAiPosDriversComponent_Fragment
  | ComponentFields_RndTrialsKpiCategoriesComponent_Fragment
  | ComponentFields_RndTrialsKpiDetailTableComponent_Fragment
  | ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment
  | ComponentFields_RndValueOverviewChartComponent_Fragment
  | ComponentFields_RndValueOverviewChartHeaderComponent_Fragment
  | ComponentFields_SpendCmoPulseDialComponent_Fragment
  | ComponentFields_SpendCmoPulseDriversChartComponent_Fragment
  | ComponentFields_SpendCmoPulseDriversListComponent_Fragment
  | ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment
  | ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment
  | ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment
  | ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment
  | ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment
  | ComponentFields_SpendGlocalPulseDialComponent_Fragment
  | ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment
  | ComponentFields_SpendGlocalPulseDriversListComponent_Fragment
  | ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment
  | ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment
  | ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment
  | ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment
  | ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment
  | ComponentFields_SpendHomeCardsComponent_Fragment
  | ComponentFields_SpendHomeDialComponent_Fragment
  | ComponentFields_SpendProcurementChartComponent_Fragment
  | ComponentFields_SpendProcurementTableComponent_Fragment
  | ComponentFields_SpendTrendingComponent_Fragment
  | ComponentFields_StoryListComponent_Fragment
  | ComponentFields_SummaryInfoComponent_Fragment
  | ComponentFields_SupplyHomeCardsComponent_Fragment
  | ComponentFields_SupplyHomeDialsComponent_Fragment
  | ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment
  | ComponentFields_SupplyPulseLeaderboardListComponent_Fragment
  | ComponentFields_SupplyShortDatedRootCausesComponent_Fragment
  | ComponentFields_SupportComponent_Fragment
  | ComponentFields_TableComponent_Fragment
  | ComponentFields_TableSwitcherComponent_Fragment
  | ComponentFields_TimelineComponent_Fragment
  | ComponentFields_UserAnalyticsComponent_Fragment
  | ComponentFields_UserPrioritiesComponent_Fragment
  | ComponentFields_VideoComponent_Fragment;

export const MetaDataFieldsFragmentDoc = gql`
  fragment MetaDataFields on MetaData {
    key
    label
    value
  }
`;
export const LinkFieldsFragmentDoc = gql`
  fragment LinkFields on Link {
    pageId
    absolutePath
    drillId
    drillIds
    filters {
      ...FilterValueFields
    }
    target
    plailistId
    previousPriorityId
  }
  ${FilterValueFieldsFragmentDoc}
`;
export const TextLinkFieldsFragmentDoc = gql`
  fragment TextLinkFields on TextLinkResult {
    value
    label
    subLabels
    linkResult {
      ...LinkFields
    }
    linkDirection
  }
  ${LinkFieldsFragmentDoc}
`;
export const RndInventoryRecommendationItemFieldsFragmentDoc = gql`
  fragment RndInventoryRecommendationItemFields on RndInventoryRecommendationItem {
    recommendationItem {
      ...RecommendationItemFields
    }
  }
  ${RecommendationItemFieldsFragmentDoc}
`;
export const RndInventoryRecommendationsListFieldsFragmentDoc = gql`
  fragment RndInventoryRecommendationsListFields on RndInventoryRecommendationsList {
    title
    items {
      ...RndInventoryRecommendationItemFields
    }
  }
  ${RndInventoryRecommendationItemFieldsFragmentDoc}
`;
export const SettingOptionFieldsFragmentDoc = gql`
  fragment SettingOptionFields on SettingOption {
    label
    value
  }
`;
export const UserSettingFieldsFragmentDoc = gql`
  fragment UserSettingFields on UserSetting {
    key
    label
    options {
      ...SettingOptionFields
    }
    defaultValue
    userValue
  }
  ${SettingOptionFieldsFragmentDoc}
`;
export const RndInventoryRecommendationsDataViewFieldsFragmentDoc = gql`
  fragment RndInventoryRecommendationsDataViewFields on RndInventoryRecommendationsDataView {
    id
    dataViewType
    metadata {
      ...MetaDataFields
    }
    inventoryRecommendationsList {
      ...RndInventoryRecommendationsListFields
    }
    link {
      ...LinkFields
    }
    availableUserSettings {
      ...UserSettingFields
    }
  }
  ${MetaDataFieldsFragmentDoc}
  ${RndInventoryRecommendationsListFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${UserSettingFieldsFragmentDoc}
`;
export const ComponentFieldsFragmentDoc = gql`
  fragment ComponentFields on Component {
    id
    code
    componentType
    icon
  }
`;
export const RndInventoryRecommendationsComponentFieldsFragmentDoc = gql`
  fragment RndInventoryRecommendationsComponentFields on RndInventoryRecommendationsComponent {
    filters {
      ...FilterComponentFields
    }
    id
    componentType
    viewType
    code
    icon
  }
  ${FilterComponentFieldsFragmentDoc}
`;
export const RndInventoryRecommendationsBreakdownDataViewFieldsFragmentDoc = gql`
  fragment RndInventoryRecommendationsBreakdownDataViewFields on RndInventoryRecommendationsBreakdownDataView {
    id
    dataViewType
    breakdownTabs {
      ...TableComponentFields
    }
    metadata {
      ...MetaDataFields
    }
    availableUserSettings {
      ...UserSettingFields
    }
    pageHeader
    pageSubHeader
  }
  ${TableComponentFieldsFragmentDoc}
  ${MetaDataFieldsFragmentDoc}
  ${UserSettingFieldsFragmentDoc}
`;
export const RndInventoryRecommendationsBreakdownComponentFieldsFragmentDoc = gql`
  fragment RndInventoryRecommendationsBreakdownComponentFields on RndInventoryRecommendationsBreakdownComponent {
    filters {
      ...FilterComponentFields
    }
    id
    componentType
    viewType
    code
    icon
  }
  ${FilterComponentFieldsFragmentDoc}
`;
export const RndHomeDialFieldsFragmentDoc = gql`
  fragment RndHomeDialFields on RndDialItem {
    dialItem {
      ...DialItemFields
    }
  }
  ${DialItemFieldsFragmentDoc}
`;
export const RndHomeDialsDataViewFieldsFragmentDoc = gql`
  fragment RndHomeDialsDataViewFields on RndHomeDialsDataView {
    ...DataViewFields
    title
    annotation
    annotationDate
    items {
      ...RndHomeDialFields
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${RndHomeDialFieldsFragmentDoc}
`;
