import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { colors } from '../../../../theme/default/colors';
import { ElementType, Screen } from '../../types/agentJsonData';
import { AgentContentRenderer } from '../AgentContentRenderer/AgentContentRenderer';
import AgentTextElement from '../AgentScreenItems/AgentTextElement';

interface AgentDefaultProps {
  screen: Screen;
}

const AgentDefault: React.FC<AgentDefaultProps> = ({ screen }) => {
  return (
    <AgentContentRenderer content={screen.content}>
      {(elements) =>
        elements.map((element, elementKey) => (
          <Box key={elementKey}>
            {element.type === ElementType.Text && <AgentTextElement element={element} />}

            {element.type === ElementType.SubtitleText && (
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 510,
                    fontSize: 16,
                    lineHeight: '19px',
                    color: colors.neutrals.grey4,
                  }}
                  textAlign={element?.alignment}
                >
                  {element?.content}
                </Typography>
              </Stack>
            )}
          </Box>
        ))
      }
    </AgentContentRenderer>
  );
};

export default AgentDefault;
