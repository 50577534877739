import { StoryDecision } from '@aily/graphql-sdk/schema';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { useAilyAgent } from '../../providers';
import { AgentJsonData, Screen, ScreenType, SliderValueType } from '../../types/agentJsonData';
import { AgentDecisionLeversPopup } from '../AgentDecisionLeversPopup/AgentDecisionLeversPopup';
import AgentDefault from '../AgentDefault/AgentDefault';
import { AgentElementRenderer } from '../AgentElementRenderer/AgentElementRenderer';
import { DecisionLevers } from '../AgentScreenItems';
import { PopupProps } from '../Popup';

export interface AgentSlideshowSlideSectionBuilderProps {
  screen: Screen;
  titleRef?: React.Ref<HTMLElement>;
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  StackProps?: StackProps;
  baseAudioURL: string;
  agentJsonData: AgentJsonData;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onSetExtractedLeversScreens: (screens: Screen[]) => void;
  switchValue: { value: number };
  onSetSwitchValue: ({ value }: { value: number }) => void;
  sliderValues: SliderValueType;
  onSetSliderValues: (sliderValues: SliderValueType) => void;
  optimizationScope: number;
  onSetOptimizationScope: (value: number) => void;
}

// Popup component that includes the PopupContent
export const ScreenPopupComponent: React.FC<
  PopupProps & {
    screens: Screen[];
    baseAudioURL: string;
    agentJsonData: AgentJsonData;
    decision: StoryDecision | null;
    onAgentClose?: () => void;
    onBackdropClose?: (forceRefetch: boolean) => void;
  }
> = ({
  screens,
  title,
  open,
  onClose,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
}) => {
  const { exit } = useAilyAgent();

  const handleClose = useCallback(() => {
    exit();
    onClose?.();
  }, [onClose, exit]);

  if (!screens) return null;

  return (
    <AgentDecisionLeversPopup
      title={title}
      screens={screens}
      open={open}
      onClose={handleClose}
      baseAudioURL={baseAudioURL}
      agentJsonData={agentJsonData}
      decision={decision}
      textElementStyle={{ paddingBottom: screens[0].screen_type === ScreenType.Default ? 15 : 5 }}
      onAgentClose={onAgentClose}
      onBackdropClose={onBackdropClose}
    />
  );
};

// Slideshow section builder
export const AgentSlideshowSlideSectionBuilder: React.FC<
  AgentSlideshowSlideSectionBuilderProps
> = ({
  screen,
  titleRef,
  onAfterScreen,
  StackProps,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
  onSetExtractedLeversScreens,
  switchValue,
  onSetSwitchValue,
  sliderValues,
  onSetSliderValues,
  optimizationScope,
  onSetOptimizationScope,
}) => {
  const renderTypeAgnosticScreens = useMemo(() => {
    return (
      <Box>
        {screen.content?.map(({ elements: row }, rowIndex) => (
          <Stack
            flexDirection="row"
            gap="20px"
            alignItems="center"
            key={`row-${rowIndex}`}
            flexWrap="wrap"
          >
            {row?.map((col, colIndex) => (
              <Box
                key={`col-${rowIndex}-${colIndex}`}
                boxSizing="border-box"
                padding="2px"
                flexDirection="row"
                gap="10px"
                flex="1 1 50%"
              >
                <Stack flexDirection="row" gap="10px" alignItems="center">
                  {col.map((elements, elementsIndex) => (
                    <Stack width="100%" key={`elements-${elementsIndex}`}>
                      {elements.map((element, elementKey) => (
                        <AgentElementRenderer
                          key={elementKey}
                          element={element}
                          screen={screen}
                          titleRef={titleRef}
                          agentJsonData={agentJsonData}
                          decision={decision}
                          chartHeight={240}
                          chartWidth={290}
                          onAgentClose={onAgentClose}
                          onBackdropClose={onBackdropClose}
                        />
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        ))}
      </Box>
    );
  }, [screen, titleRef, agentJsonData, decision]);

  const renderScreen = useMemo(() => {
    if (!isNil(decision)) {
      return <>{renderTypeAgnosticScreens}</>;
    }

    if (screen.screen_type === ScreenType.Intro) {
      return <>{renderTypeAgnosticScreens}</>;
    } else if (screen.screen_type === ScreenType.Levers) {
      return (
        <DecisionLevers
          data-testid="decision-levers-screen"
          screen={screen}
          baseAudioURL={baseAudioURL}
          agentJsonData={agentJsonData}
          decision={decision}
          onAgentClose={onAgentClose}
          onBackdropClose={onBackdropClose}
          onSetExtractedLeversScreens={onSetExtractedLeversScreens}
          switchValue={switchValue}
          onSetSwitchValue={onSetSwitchValue}
          sliderValues={sliderValues}
          onSetSliderValues={onSetSliderValues}
          optimizationScope={optimizationScope}
          onSetOptimizationScope={onSetOptimizationScope}
        />
      );
    } else if (screen.screen_type === ScreenType.Default) {
      return <AgentDefault screen={screen} />;
    } else {
      return <>{renderTypeAgnosticScreens}</>;
    }
  }, [screen]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="342px"
      flex={1}
      minWidth={0}
      {...StackProps}
    >
      <Stack
        direction="column"
        height={520}
        flex={1}
        alignItems="flex-start"
        padding="24px"
        width="342px"
        sx={{
          background:
            screen.screen_type === ScreenType.Levers ? 'transparent' : 'rgba(64, 64, 64, 0.35)',
          border: screen.screen_type === ScreenType.Levers ? '1px solid #595959' : 'none',
          borderRadius: '12px',
        }}
        {...(screen.screen_type === ScreenType.Levers && { alignItems: 'center' })}
      >
        <Typography ref={titleRef} variant="h9">
          {screen.screen_title || 'Decision Levers'}
        </Typography>
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          marginTop="10px"
          alignItems="flex-start"
          display="flex"
          flex={1}
        >
          {renderScreen}
        </Box>
      </Stack>
      {onAfterScreen?.(screen)}
    </Box>
  );
};
