import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react';

import { colors } from '../../../../theme/default/colors';

interface AgentInfoProps {
  text: string;
  text2: string;
}

const StyledButton = styled(Stack)(() => ({
  borderRadius: 100,
  backgroundColor: 'rgba(14, 51, 31, 1)',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  height: 40,
  minWidth: 170,
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 15,
  paddingRight: 15,
}));

const AgentInfo: React.FC<AgentInfoProps> = ({ text, text2 }) => {
  return (
    <Box data-testid="decisions_info_panel" display="flex" justifyContent="center">
      <StyledButton gap="10px">
        <Typography variant="h8" color={colors.primary.green}>
          {text}
        </Typography>
        <Typography color={colors.neutrals.white}>{text2}</Typography>
      </StyledButton>
    </Box>
  );
};

export default AgentInfo;
